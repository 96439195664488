import * as cookieTools from "../cookieTools";
import { HandleError, HandleResponse } from "./apiUtils";
const baseUrl = "/api/rate/";

export class SearchQuery {
   constructor(custID, terminalID, destination, validRatesOnly) {
      this.custID = custID;
      this.terminalID = terminalID;
      this.destination = destination;
      this.validRatesOnly = validRatesOnly;
   }
}

export async function searchRatesPortal(searchQuery = []) {
   let token = cookieTools.getCookie("rate-guide-token");

   return await fetch(baseUrl + "searchRatesPortal", {
      method: "post",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
      body: JSON.stringify({ searchQuery }),
   })
      .then(await HandleResponse)
      .catch(await HandleError);
}

export function saveRate(rate) {
   let token = cookieTools.getCookie("rate-guide-token");

   var arg = {
      rate: rate,
   };

   return fetch(baseUrl + "saveRates", {
      method: "post",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
      body: JSON.stringify(arg),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function searchAllRates(terminalID, destination) {
   let token = cookieTools.getCookie("rate-guide-token");

   return fetch(baseUrl + "searchRates", {
      method: "post",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
      body: JSON.stringify({
         searchQuery: [
            {
               terminalID: terminalID,
               destination: destination,
            },
         ],
      }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function deleteRate(rateID, termID, custID) {
   let token = cookieTools.getCookie("rate-guide-token");

   let url = baseUrl + "deleteRate?";

   if (rateID) {
      url += "rateID=" + rateID;
   }

   if (termID) {
      url += "&termID=" + termID;
   }

   if (custID) {
      url += "&custID=" + custID;
   }

   return fetch(url, {
      method: "delete",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
   })
      .then(HandleResponse)
      .catch(HandleError);
}
