import * as cookieTools from "../cookieTools";
import { HandleError, HandleResponse } from "./apiUtils";
const baseUrl = "/api/adminMessage/";

/**
 * Get messages for the user.
 * @returns
 */
export function getUserMessages() {
   const token = cookieTools.getCookie("rate-guide-token");
   const url = `${baseUrl}getUserMessages`;

   return fetch(url, {
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
   })
      .then(HandleResponse)
      .catch(HandleError);
}

/**
 * Update the date read for the message.
 * @param {Array} messageIDs
 */
export function setUserMessages(messageIDs) {
   const token = cookieTools.getCookie("rate-guide-token");
   const url = `${baseUrl}setUserMessages`;

   return fetch(url, {
      method: "POST",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
      body: JSON.stringify(messageIDs),
   })
      .then(HandleResponse)
      .catch(HandleError);
}
