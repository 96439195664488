import actionTypes from "../actions/actionTypes";

const initialState = {
   currentUser: {},
   avatar: null,
   customer: {},
};

export default function userReducer(state = initialState, action) {
   switch (action.type) {
      case actionTypes.CREATE_USER:
         return { ...state, user: action.user };
      case actionTypes.LOAD_USER_SUCCESS:
         return action.user;
      case actionTypes.LOGIN_USER:
         return { ...state, currentUser: action.user };
      case actionTypes.LOAD_USERS:
         return { ...state, internalUsers: action.internalUsers };
      case actionTypes.LOGOUT:
         return { ...state, currentUser: {} };
      case actionTypes.SET_AVATAR:
         return { ...state, avatar: action.avatar };
      default:
         return state;
   }
}
