import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { verifyToken } from "../../api/userApi";
import * as cookieTools from "../../cookieTools";
import actionTypes from "../../redux/actions/actionTypes";
import { loginUser } from "../../redux/actions/userActions";
import backgroundLogo from "../Logo/BackgroundWhiteNOTRUCK.png";
import LoginForm from "./LoginForm";

const LoginPage = (props) => {
   let [state, setState] = useState({ email: "", password: "" });
   let [loading, setLoading] = useState(false);
   let [validated, setValidated] = useState(false);
   let [error, setError] = useState(false);

   const history = useHistory();

   useEffect(() => {
      if (cookieTools.checkCookie("rate-guide-token") && cookieTools.checkCookie("rate-guide-userID")) {
         verifyToken()
            .then((resp) => setValidated(true))
            .catch((err) => setValidated(false));
      }
   }, []);

   function handleEnterKey(event) {
      if (event.key === "Enter") {
         handleSubmit();
      }
   }

   function handleSubmit() {
      setLoading(true);
      props
         .loginUser({
            email: state.email,
            password: state.password,
         })
         .then((resp) => {
            if (resp) {
               setValidated(true);
            } else {
               setError("Login failed, please check your email/password.");
               setLoading(false);
            }
         });
   }
   function handleEmailChange(event) {
      setState({
         ...state,
         email: event.target.value,
      });
   }
   function handlePasswordChange(event) {
      setState({
         ...state,
         password: event.target.value,
      });
   }
   function handleRegisterClicked(event) {}

   function handleResetClicked() {
      history.push("/login/forgotPassword");
   }

   return (
      <>
         <div
            style={{
               backgroundImage: `url(${backgroundLogo})`,
               backgroundRepeat: "no-repeat",
               position: "absolute",
               height: "100%",
               width: "100%",
               backgroundSize: "cover",
            }}
         >
            {validated && <Redirect to={"/"}></Redirect>}
            {error && <Alert severity="error"> {error} </Alert>}
            <LoginForm
               email={state.email}
               password={state.password}
               loading={loading}
               handleEmailChange={handleEmailChange}
               handlePasswordChange={handlePasswordChange}
               handleSubmit={handleSubmit}
               handleEnterKey={handleEnterKey}
               handleRegister={handleRegisterClicked}
               handleReset={handleResetClicked}
               loggedOut={props.match.params.loggedOut}
            ></LoginForm>
         </div>
      </>
   );
};

function mapStateToProps(state, ownProps) {
   return {};
}

function mapDispatchToProps(dispatch) {
   return {
      loginUser: (user) => dispatch(loginUser(user)),
      saveUser: (user) => dispatch({ type: actionTypes.LOGIN_USER, user }),
   };
}

LoginPage.propTypes = {
   match: PropTypes.shape({
      params: PropTypes.shape({
         redirectURL: PropTypes.string,
         loggedOut: PropTypes.string,
      }),
   }),
   loginUser: PropTypes.func,
};

LoginPage.defaultProps = {
   match: { params: { redirectURL: "", loggedOut: "" } },
   loginUser: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
