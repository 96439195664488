import { calcCPGrate } from "./calcCPGrate";

export function getRateFuelAmount(item) {
   try {
      item.rate.selectedRateInfo = item.rate.rateInfo;

      if (!item.fuelTariff) return 0;

      let cpm = 0;
      let ppr = 0;

      let cpmOverRide = null;
      let pprOverRide = null;
      let base_rateOverRide = null;

      if (item.overrides) {
         cpmOverRide = item.overrides.cpm;
         pprOverRide = item.overrides.ppr;
         base_rateOverRide = item.overrides.base_rate;
      }

      if (cpmOverRide) {
         return parseFloat(cpmOverRide) * item.rate.selectedRateInfo.miles;
      }

      if (pprOverRide) {
         return (
            (parseFloat(pprOverRide) / 100) *
            parseFloat(base_rateOverRide ? base_rateOverRide : item.rate.selectedRateInfo.base_rate)
         );
      }

      //get the current rate depending on whether Over Under exists or not.
      if (item.fuelTariff.overUnder) {
         cpm = item.fuelTariff.overUnder.cpm;
         ppr = item.fuelTariff.overUnder.ppr;
      } else {
         cpm = item.fuelTariff.tariffInfo.cpm;
         ppr = item.fuelTariff.tariffInfo.ppr;
      }

      const cpgRate = calcCPGrate(
         base_rateOverRide ? base_rateOverRide : item.rate.selectedRateInfo.base_rate,
         item.rate.selectedRateInfo.tolls,
         item.rate.selectedRateInfo.bobtail,
      );

      if (cpm > 0.0) {
         return parseFloat(cpm) * item.rate.selectedRateInfo.miles;
      }

      //PPR
      return (parseFloat(ppr) / 100) * parseFloat(cpgRate);
   } catch (err) {
      handleError(err);
   }
}
