import { calcCPGrate } from "./calcCPGrate";

export function getRateFuelAmountRateResults(rate) {
   try {
      rate.selectedRateInfo = rate.rateInfo;

      if (!rate.fuelTariff) return 0;

      let cpm = 0;
      let ppr = 0;

      let base_rateOverRide = null;

      //get the current rate depending on whether Over Under exists or not.
      if (rate.fuelTariff.overUnder) {
         cpm = rate.fuelTariff.overUnder.cpm;
         ppr = rate.fuelTariff.overUnder.ppr;
      } else {
         cpm = rate.fuelTariff.tariffInfo.cpm;
         ppr = rate.fuelTariff.tariffInfo.ppr;
      }

      const cpgRate = calcCPGrate(
         base_rateOverRide ? base_rateOverRide : rate.selectedRateInfo.base_rate,
         rate.selectedRateInfo.tolls,
         rate.selectedRateInfo.bobtail,
      );

      if (cpm > 0.0) {
         return parseFloat(cpm) * rate.selectedRateInfo.miles;
      }

      //PPR
      return (parseFloat(ppr) / 100) * parseFloat(cpgRate);
   } catch (err) {
      handleError(err);
   }
}
