import Collapse from "@material-ui/core/Collapse";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AddBox, Assignment, ExpandLess, ExpandMore } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { version } from "../../package.json";
import cpgLogo from "./Logo/Container-Port-White-Logotype.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
   root: {
      display: "flex",
   },
   drawer: {
      [theme.breakpoints.up("sm")]: {
         width: drawerWidth,
         flexShrink: 0,
      },
   },
   toolbar: theme.mixins.toolbar,
   drawerPaper: {
      width: drawerWidth,
      backgroundColor: "#010440",
   },
   content: {
      flexGrow: 1,
      padding: theme.spacing(3),
   },
   listItem: {
      backgroundColor: "#010440",
      color: "white",
      "&:hover": {
         backgroundColor: "#004B8D",
      },
   },

   listItemText: {
      color: "white",
      paddingLeft: "1rem",
   },

   listItemSelected: {
      backgroundColor: "white",
      opacity: "75%",
      "&:hover": {
         backgroundColor: "white",
      },
   },

   listItemTextSelected: {
      color: "black",
      paddingLeft: "1rem",
   },

   listItemIconSelected: {
      color: "black",
      display: "contents",
      width: "max-content",
   },

   listItemIcon: {
      color: "white",
      display: "contents",
      width: "max-content",
   },

   hrStyle: {
      borderColor: "whitesmoke",
      padding: "0px",
      margin: "0px",
   },
   imgStyle: {
      height: "67px",
      padding: "2%",
      marginTop: "5px",
   },
   dividerImg: {
      backgroundColor: "#B0BBC3",
      height: "1px",
      width: "85%",
      marginLeft: "20px",
   },
   divImg: {
      textAlign: "center",
   },
}));

function Sidebar(props) {
   const { container } = props;
   const classes = useStyles();
   const theme = useTheme();
   const [mobileOpen, setMobileOpen] = React.useState(false);
   const history = useHistory();

   let [state, setState] = useState({
      activeTab: "ratesearch",
   });

   let [open, setOpen] = useState({});

   useEffect(() => {
      var openRoutes = {};

      props.routes.forEach((route) => {
         if (route.subRoutes) {
            openRoutes[route.name] = false;
         }
      });

      setOpen(openRoutes);
   }, []);

   const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
   };

   function handleClick(route) {
      setState({
         ...state,
         activeTab: route.name,
      });

      history.push(route.path);
   }

   let sidebarRoutes = props.routes.filter((rt) => rt.sidebar === true);

   const drawer = (
      <div className={classes.toolbar}>
         <List>
            {sidebarRoutes.map((route) =>
               route.subRoutes ? (
                  <>
                     <ListItem
                        button
                        key={route.name}
                        onClick={() => setOpen({ ...open, [route.name]: !open[route.name] })}
                        className={route.name === state.activeTab ? classes.listItemSelected : classes.listItem}
                     >
                        <ListItemIcon
                           className={
                              route.name === state.activeTab ? classes.listItemIconSelected : classes.listItemIcon
                           }
                        >
                           {route.icon}
                        </ListItemIcon>
                        <ListItemText
                           primary={route.text}
                           className={
                              route.name === state.activeTab ? classes.listItemTextSelected : classes.listItemText
                           }
                        />
                        {open[route.name] ? (
                           <ExpandLess style={{ color: "white" }} />
                        ) : (
                           <ExpandMore style={{ color: "white" }} />
                        )}
                     </ListItem>
                     <Collapse in={open[route.name]} timeout="auto" unmountOnExit>
                        {route.subRoutes.map((sub) => {
                           return (
                              <ListItem
                                 button
                                 key={sub.name}
                                 onClick={() => handleClick(sub)}
                                 className={sub.name === state.activeTab ? classes.listItemSelected : classes.listItem}
                              >
                                 <ListItemIcon
                                    className={
                                       sub.name === state.activeTab
                                          ? classes.listItemIconSelected
                                          : classes.listItemIcon
                                    }
                                 >
                                    {sub.icon}
                                 </ListItemIcon>
                                 <ListItemText
                                    primary={sub.text}
                                    className={
                                       sub.name === state.activeTab
                                          ? classes.listItemTextSelected
                                          : classes.listItemText
                                    }
                                 />
                              </ListItem>
                           );
                        })}
                     </Collapse>
                  </>
               ) : (
                  <ListItem
                     button
                     key={route.name}
                     onClick={() => handleClick(route)}
                     className={route.name === state.activeTab ? classes.listItemSelected : classes.listItem}
                  >
                     <ListItemIcon
                        className={route.name === state.activeTab ? classes.listItemIconSelected : classes.listItemIcon}
                     >
                        {route.icon}
                     </ListItemIcon>
                     <ListItemText
                        primary={route.text}
                        className={route.name === state.activeTab ? classes.listItemTextSelected : classes.listItemText}
                     />
                  </ListItem>
               ),
            )}
            <div
               style={{
                  position: "fixed",
                  bottom: "10px",
                  backgroundColor: "#010440",
                  color: "white",
                  width: "240px",
                  textAlign: "center",
               }}
            >
               Version: {version}
            </div>
         </List>
      </div>
   );

   return (
      <div className={classes.root}>
         <CssBaseline />
         <nav className={classes.drawer} aria-label="mailbox folders">
            <Divider className={classes.hrStyle} />
            <Hidden smUp implementation="css">
               <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === "rtl" ? "right" : "left"}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                     paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                     keepMounted: true,
                  }}
               >
                  {drawer}
               </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
               <Drawer
                  classes={{
                     paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
               >
                  <div className={classes.divImg}>
                     <img className={classes.imgStyle} src={cpgLogo}></img>
                  </div>

                  <Divider className={classes.dividerImg} />

                  {drawer}
               </Drawer>
            </Hidden>
         </nav>
      </div>
   );
}

Sidebar.propTypes = {
   routes: PropTypes.array.isRequired,
};

Sidebar.defaultProps = {
   routes: [],
};

function mapStateToProps(state, ownProps) {
   let props = {
      routes: [],
      parentState: ownProps.parentState,
      setParentState: ownProps.setParentState,
   };

   props.routes = [
      {
         name: "quotes",
         icon: <Assignment style={{ marginLeft: "1rem" }} />,
         text: "Quote History",
         sidebar: true,
         path: "/quotes",
      },
      {
         name: "RateSearch",
         icon: <AddBox style={{ marginLeft: "1rem" }} />,
         text: "Rate Search",
         sidebar: true,
         path: "/",
      },
   ];

   return props;
}

Sidebar.propTypes = {
   container: PropTypes.any,
};

Sidebar.defaultProps = {
   container: null,
};

export default withRouter(connect(mapStateToProps)(Sidebar));
