import { Button, Collapse, Divider, FormControl, Grid, IconButton, Paper, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { requestPasswordReset } from "../../api/userApi";
import { validateEmail } from "../../common/validateEmail";
import backgroundLogo from "../Logo/BackgroundWhiteNOTRUCK.png";
import cpgLogo from "../Logo/ContainerPort-Full-Color-Logo-RGB-300dpi.png";

const forgotPassword = () => {
   let [email, setEmail] = useState("");
   const [alertMessage, setAlertMessage] = useState("");
   const [alertType, setAlertType] = useState("warning");

   const history = useHistory();

   function handleEmailChange(event) {
      setEmail(event.target.value);
   }

   function handleEnterKey(event) {
      if (event.key === "Enter") {
         handleResetClicked();
      }
   }

   function handleResetClicked() {
      if (!email) {
         openAlertMessage("Please input your account email to request a password reset.", "warning");
         return;
      }

      const validEmail = validateEmail(email);

      if (validEmail) {
         openAlertMessage(validEmail, "warning");
         return;
      }

      requestPasswordReset(email).then((resp) => {
         if (resp.success) {
            openAlertMessage("If a user with the provided email exists, a password reset email was sent.", "success");
            return;
         }

         openAlertMessage(
            "Unable to reset your password. If you received this in error, please contact your administrator.",
            "error",
         );
      });
   }

   async function openAlertMessage(alertMessage = "", alertType = "") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   return (
      <div
         style={{
            backgroundImage: `url(${backgroundLogo})`,
            backgroundRepeat: "no-repeat",
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundSize: "cover",
         }}
      >
         <Collapse in={alertMessage ? true : false}>
            <Alert
               severity={alertType}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setAlertMessage("");
                     }}
                  >
                     <CloseIcon />
                  </IconButton>
               }
            >
               {alertMessage}
            </Alert>
         </Collapse>
         <Grid container direction={"row"} justify="space-between" alignItems="center" style={{ marginTop: "10rem" }}>
            <div style={{ width: "406px", maxWidth: "406px", margin: "0 auto" }}>
               <Paper elevation={3} style={{ padding: "3%" }}>
                  <div>
                     <img src={cpgLogo} style={{ width: "10rem" }} />
                  </div>
                  <Divider />
                  <h3 style={{ fontFamily: "Helvetica Neue, Helvetica, Arial", textAlign: "center" }}>
                     Forgot Password
                  </h3>
                  <p
                     style={{
                        fontFamily: "Helvetica Neue, Helvetica, Arial",
                        textAlign: "center",
                        marginBottom: "1rem",
                     }}
                  >
                     A reset link will be sent to your email if there is an account registered to your email.
                  </p>
                  <div style={{ textAlign: "-webkit-center" }}>
                     <FormControl controlid="email" style={{ marginBottom: "1rem", width: "75%" }}>
                        <TextField
                           id="email"
                           type="email"
                           autoFocus
                           value={email}
                           onChange={handleEmailChange}
                           placeholder="Email"
                           onKeyDown={handleEnterKey}
                        />
                     </FormControl>
                  </div>
                  <div style={{ marginTop: "1.5rem", textAlignLast: "center" }}>
                     <div style={{ marginBottom: "1rem" }}>
                        <Button
                           variant="contained"
                           color="primary"
                           type="text"
                           disabled={false}
                           onClick={handleResetClicked}
                           style={{ width: "70%" }}
                        >
                           Request Password Reset
                        </Button>
                     </div>
                     <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => history.push("/login")}
                        style={{ width: "70%" }}
                     >
                        Cancel
                     </Button>
                  </div>
               </Paper>
            </div>
         </Grid>
      </div>
   );
};

export default forgotPassword;
