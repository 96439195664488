import { AppBar, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RefreshRoundedIcon from "@material-ui/icons/RefreshRounded";
import PropTypes from "prop-types";
import React from "react";
import "../styles/truckBroke.css";
import containerLogo from "./Logo/ContainerPort-Logo-white.png";

const useStyles = makeStyles((theme) => ({
   containerStyle: {
      backgroundColor: "#002D72",
      width: "100%",
      zIndex: "-1",
   },
   gridStyle: {
      backgroundColor: "#002D72",
      width: "100%",
      height: "100%",
      zIndex: "-1",
      marginTop: "400px",
   },
   title: {
      flexGrow: 1,
      textAlign: "center",
      color: "white",
   },
}));

function ErrorHandler({ error, resetErrorBoundary }) {
   const classes = useStyles();

   return (
      <div>
         <AppBar className={classes.containerStyle}>
            <Typography variant="h2" className={classes.title}>
               :&apos;(
            </Typography>
         </AppBar>
         <hr
            style={{
               position: "absolute",
               top: "290px",
               border: "52px solid #404040",
               width: "150%",
               zIndex: "3",
               marginLeft: "-2rem",
            }}
         ></hr>
         <div className="truckBroke-wrapper" style={{ position: "relative", zIndex: 2, marginTop: "235px" }}>
            <div className="truckBroke">
               <div className="truck-container">
                  <img style={{ width: "65px", paddingLeft: "5px", paddingTop: "15px" }} src={containerLogo} alt="" />
               </div>
               <div className="glases"></div>
               <div className="bonet"></div>
               <div className="base"></div>
               <div className="base-aux"></div>
               <div className="wheel-back"></div>
               <div className="wheel-front"></div>
               <div className="smoke"></div>
               <div className="smoke2"></div>
               <div className="smoke3"></div>
               <div className="smoke4"></div>
            </div>
         </div>
         <AppBar className={classes.gridStyle}>
            <Grid container direction="rows" alignItems="center">
               <Grid item xs={12}>
                  <Typography variant="h2" className={classes.title}>
                     Looks Like We Brokedown!
                  </Typography>
               </Grid>
               <Grid item xs={12}>
                  <Typography className={classes.title}>
                     Please contact your administrator regarding the below error.
                  </Typography>
               </Grid>
               <Grid item xs={12}>
                  <Typography className={classes.title}>{error.toString()}</Typography>
               </Grid>
               <Grid item xs={12}>
                  <Typography className={classes.title}>Go ahead and refresh the site and try again:</Typography>
               </Grid>
               <Grid container spacing={0} direction="column" alignItems="center" justify="center">
                  <Tooltip title="Refresh Page" arrow>
                     <IconButton
                        id="btn-refresh"
                        variant="contained"
                        className={classes.title}
                        onClick={resetErrorBoundary}
                     >
                        <RefreshRoundedIcon style={{ fontSize: "2rem" }}></RefreshRoundedIcon>
                     </IconButton>
                  </Tooltip>
               </Grid>
            </Grid>
         </AppBar>
      </div>
   );
}

ErrorHandler.propTypes = {
   error: PropTypes.any,
   resetErrorBoundary: PropTypes.any,
};

ErrorHandler.defaultProps = {
   error: null,
   resetErrorBoundary: null,
};

export default ErrorHandler;
