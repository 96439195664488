import { Button, Divider, FormControl, Grid, List, ListItem, Paper, TextField } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as userApi from "../../api/userApi";
import backgroundLogo from "../Logo/BackgroundWhiteNOTRUCK.png";
import cpgLogo from "../Logo/ContainerPort-Full-Color-Logo-RGB-300dpi.png";
var _ = require("lodash");

const ResetPasswordPage = (props) => {
   const history = useHistory();
   let [state, setState] = useState({
      email: "",
      newPassword: "",
      repeatPassword: "",
      error: null,
   });
   let [policy, setPolicy] = useState({
      policy1: false,
      policy2: false,
      policy3: false,
      policy4: false,
   });

   function checkPolicy(password) {
      var newPolicy = _.cloneDeep(policy);
      var lowerCase = new RegExp(/[a-z]/);
      var upperCase = new RegExp(/[A-Z]/);
      var specialChar = new RegExp(/[!@#$%^&*)(+=._-]/);
      var minChar = new RegExp(/.{8,}/);
      var minNum = new RegExp(/(?=.*?[0-9])/);

      //do password validate for policy 1 then set the object true or false
      if (password.match(lowerCase) && password.match(upperCase)) {
         newPolicy.policy1 = true;
      } else {
         newPolicy.policy1 = false;
      }

      if (password.match(minNum)) {
         newPolicy.policy2 = true;
      } else {
         newPolicy.policy2 = false;
      }

      if (password.match(specialChar)) {
         newPolicy.policy3 = true;
      } else {
         newPolicy.policy3 = false;
      }

      if (password.match(minChar)) {
         newPolicy.policy4 = true;
      } else {
         newPolicy.policy4 = false;
      }

      //check other ones as well

      //set the hook
      setPolicy(newPolicy);
   }

   function onEmailChange(evt) {
      //todo: any email validation
      setState({
         ...state,
         email: evt.target.value,
      });
   }

   function onPasswordChange(evt) {
      checkPolicy(evt.target.value);
      //todo: validate the new pw, make sure it meets complexity requirements
      setState({
         ...state,
         newPassword: evt.target.value,
      });
   }

   function onRepeatChange(evt) {
      //todo: validate
      setState({
         ...state,
         repeatPassword: evt.target.value,
      });
   }

   function onSubmit(evt) {
      evt.preventDefault();
      if (state.newPassword !== state.repeatPassword) {
         setState({
            ...state,
            error: "Passwords do not match.",
         });
         return;
      }

      userApi
         .resetPassword(props.match.params.uid, props.match.params.jwt, state.newPassword)
         .then((resp) => {
            alert("Success!  Redirecting to login page.");
            history.push("/login");
         })
         .catch((err) => {
            alert(err);
         });
   }

   return (
      <>
         <div
            style={{
               backgroundImage: `url(${backgroundLogo})`,
               backgroundRepeat: "no-repeat",
               position: "absolute",
               height: "100%",
               width: "100%",
               backgroundSize: "cover",
            }}
         >
            <Grid
               container
               direction={"row"}
               justify="space-between"
               alignItems="center"
               style={{ marginTop: "10rem" }}
            >
               <div style={{ width: "406px", maxWidth: "406px", margin: "0 auto" }}>
                  <Paper elevation={3} style={{ padding: "3%" }}>
                     <div>
                        <img src={cpgLogo} style={{ width: "10rem" }} alt="" />
                     </div>

                     <Divider />

                     <h3 style={{ fontFamily: "Helvetica Neue, Helvetica, Arial", textAlign: "center" }}>
                        Reset Password
                     </h3>

                     <form onSubmit={onSubmit}>
                        <div style={{ textAlign: "-webkit-center" }}>
                           <div>
                              <FormControl controlid="email" style={{ width: "75%", marginBottom: "1rem" }}>
                                 <TextField
                                    type="text"
                                    onChange={onEmailChange}
                                    value={state.email}
                                    placeholder="email"
                                 />
                              </FormControl>
                           </div>

                           <FormControl controlid="password" style={{ width: "75%", marginBottom: "1rem" }}>
                              <TextField
                                 type="password"
                                 value={state.password}
                                 onChange={onPasswordChange}
                                 placeholder="New Password"
                              />
                           </FormControl>

                           <FormControl controlid="password" style={{ width: "75%" }}>
                              <TextField
                                 type="password"
                                 onChange={onRepeatChange}
                                 value={state.repeatPassword}
                                 placeholder="Repeat Password"
                              />
                           </FormControl>

                           <div style={{ marginTop: "2rem" }}>
                              <div style={{ marginLeft: "1rem", marginBottom: "0.50rem", float: "left" }}>
                                 {"Your password must have: "}
                              </div>
                              <List>
                                 <ListItem>
                                    <div style={{ display: "inline-flex" }}>
                                       {" "}
                                       <div style={{ marginRight: "0.25rem" }}>
                                          {policy.policy1 ? (
                                             <DoneIcon style={{ color: "green", fontSize: "large" }} />
                                          ) : (
                                             <ClearIcon style={{ color: "red", fontSize: "large" }} />
                                          )}
                                       </div>
                                       <div>{"Lowercase and uppercase letters"}</div>
                                    </div>{" "}
                                    <br></br>
                                 </ListItem>
                                 <ListItem>
                                    <div style={{ display: "inline-flex" }}>
                                       <div style={{ marginRight: "0.25rem" }}>
                                          {policy.policy2 ? (
                                             <DoneIcon style={{ color: "green", fontSize: "large" }} />
                                          ) : (
                                             <ClearIcon style={{ color: "red", fontSize: "large" }} />
                                          )}
                                       </div>
                                       <div>{"Contain 1 number"}</div>
                                    </div>{" "}
                                    <br></br>
                                 </ListItem>
                                 <ListItem>
                                    <div style={{ display: "inline-flex" }}>
                                       <div style={{ marginRight: "0.25rem" }}>
                                          {policy.policy3 ? (
                                             <DoneIcon style={{ color: "green", fontSize: "large" }} />
                                          ) : (
                                             <ClearIcon style={{ color: "red", fontSize: "large" }} />
                                          )}
                                       </div>
                                       <div>{"Contain 1 special character (!,@,$)"}</div>
                                    </div>{" "}
                                    <br></br>
                                 </ListItem>
                                 <ListItem>
                                    <div style={{ display: "inline-flex" }}>
                                       {" "}
                                       <div style={{ marginRight: "0.25rem" }}>
                                          {policy.policy4 ? (
                                             <DoneIcon style={{ color: "green", fontSize: "large" }} />
                                          ) : (
                                             <ClearIcon style={{ color: "red", fontSize: "large" }} />
                                          )}
                                       </div>
                                       <div>{"8 or more characters"}</div>
                                    </div>
                                 </ListItem>
                              </List>
                           </div>
                        </div>

                        <div style={{ marginTop: "2rem", textAlignLast: "center" }}>
                           <div style={{ marginBottom: "1rem" }}>
                              {state.error && <Alert severity="error"> {state.error} </Alert>}
                              {policy.policy1 && policy.policy2 && policy.policy3 && policy.policy4 ? (
                                 <Button variant="contained" color="primary" type="submit">
                                    {" "}
                                    Submit{" "}
                                 </Button>
                              ) : (
                                 <Button variant="contained" disabled={true} color="primary" type="submit">
                                    {" "}
                                    Submit{" "}
                                 </Button>
                              )}
                           </div>
                        </div>
                     </form>
                  </Paper>
               </div>
            </Grid>
         </div>
      </>
   );
};

ResetPasswordPage.propTypes = {
   match: PropTypes.shape({
      params: PropTypes.shape({
         uid: PropTypes.string.isRequired,
         jwt: PropTypes.string.isRequired,
      }),
   }),
};

ResetPasswordPage.defaultProps = {
   match: { params: { uid: "", jwt: "" } },
};

export default ResetPasswordPage;
