import { Box, Container } from "@material-ui/core";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import React from "react";
import cpgLogo from "../Logo/ContainerPort-Full-Color-Logo-RGB-300dpi.png";

export const ExpiredPage = () => {
   return (
      <>
         <div
            style={{
               height: "100%",
               minHeight: "100vh",
               borderTop: "2.65rem solid #002D72",
               borderBottom: "2.65rem solid #002D72",
            }}
         >
            <Container maxWidth="md">
               <Box
                  sx={{
                     width: "100%",
                     height: "100%",
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "center",
                     textAlign: "center",
                     letterSpacing: "0.05rem",
                     color: "gray",
                  }}
               >
                  <img
                     src={cpgLogo}
                     style={{ width: "18.75rem", margin: "5.1rem 0 4rem 0" }}
                     alt="Container Group Logo"
                  />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                     <VpnKeyIcon
                        style={{
                           transform: "translateY(-0.3rem) rotateY(180deg) rotate(45deg) scale(2.3)",
                           width: "3.5rem",
                           color: "#002D72",
                        }}
                     />
                     <h2 style={{ fontSize: "1.7rem" }}>Authentication Invalid</h2>
                  </Box>
                  <p style={{ fontSize: "1.1rem", fontWeight: "normal", marginTop: "4.5rem", marginBottom: "4rem" }}>
                     You are seeing this page because the link in your email has expired. Please reach out to
                     <a href="mailto:TRTSupport@containerportgroup.com"> TRTSupport@containerportgroup.com</a> to
                     request a new invite.
                  </p>
               </Box>
            </Container>
         </div>
      </>
   );
};
