import { combineReducers } from "redux";
import customer from "./customerReducer";
import notifications from "./notificationsReducer";
import terminal from "./terminalReducer";
import user from "./userReducer";

const rootReducer = combineReducers({
   customer,
   terminal,
   user,
   notifications,
});

export default rootReducer;
