import * as cookieTools from "../cookieTools";
import { HandleError, HandleResponse } from "./apiUtils";
const baseUrl = "/api/notification/";

export function getNotifications(read = null, page = 0) {
   let url = baseUrl + "getNotifications";
   if (read != null) {
      url += "?read=" + read;
   }
   url += read != null ? `&page=${page}` : `?page=${page}`;
   const token = cookieTools.getCookie("rate-guide-token");

   return fetch(url, {
      method: "get",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function removeNotification(notification_id) {
   const token = cookieTools.getCookie("rate-guide-token");

   return fetch(baseUrl + "removeNotification", {
      method: "post",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
      body: JSON.stringify({ notification_id }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}
