import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import CloseIcon from "@material-ui/icons/Close";
import EmailIcon from "@material-ui/icons/Email";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import GetAppIcon from "@material-ui/icons/GetApp";
import MenuIcon from "@material-ui/icons/Menu";
import PageviewIcon from "@material-ui/icons/Pageview";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import { saveAs } from "file-saver";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import * as orderApi from "../../api/orderApi";
import { _handleObjectProperty } from "../../common/convert";
import { getRateFuelAmount } from "../../common/getRateFuelAmount";
import { stateList } from "../../common/stateListAbbreviation";
import * as terminalActions from "../../redux/actions/terminalActions";
import "../../styles/truck.css";
import PlaceSearch from "../Google/PlaceSearch";
import RateEmail from "../ratesearch/RateEmail";

const useStyles = makeStyles(() => ({
   secondaryHeader: {
      borderBottom: "1px solid #2F3136",
      marginLeft: "240px",
      height: "50px",
      zIndex: "3",
      backgroundColor: "#F2F2F2",
      color: "#6F6F6F",
   },
   colCellTitle: {
      minHeight: "81vh",
      fontSize: "11px",
      "& .MuiDataGrid-columnsContainer": {
         backgroundColor: "#010440",
         color: "#ffffff",
      },
      "& .MuiDataGrid-sortIcon": {
         backgroundColor: "#010440",
         color: "#ffffff",
      },
   },
   root: {
      "& .super-app.expired": {
         backgroundColor: "#f44336",
         color: "#ffffff",
         textAlign: "center",
      },
      "& .super-app.completed": {
         backgroundColor: green[500],
         color: "#ffffff",
         textAlign: "center",
      },
   },
   activeBadge: {
      color: "#54B159",
   },
   expiredBadge: {
      color: "#F50057",
   },
   viewButton: {
      backgroundColor: "inherit",
      boxShadow: "1px 1px",
      color: "#010440",
      marginBottom: ".15rem",
   },
   downloadButton: {
      backgroundColor: "inherit",
      boxShadow: "1px 1px",
      color: "#010440",
      marginBottom: ".15rem",
   },
   emailButton: {
      backgroundColor: "inherit",
      boxShadow: "1px 1px",
      color: "#010440",
      marginBottom: ".15rem",
   },
   label: {
      flexDirection: "column",
      fontSize: "9px",
   },
   buttonProgress: {
      color: green[500],
   },
}));

const QuoteHistory = (props) => {
   let searchTimer; //This is to stagger filtering.

   //React Hooks.
   const [loading, setLoading] = useState(true);
   const [showAlert, setShowAlert] = useState(false);
   const [openDrawer, setOpenDrawer] = useState(false);
   const [rateEmailModal, setRateEmailModal] = useState(false);
   const [alertMessage, setAlertMessage] = useState(null);
   const [alertType, setAlertType] = useState("");
   const [orderNumber, setOrderNumber] = useState("");
   const [orderId, setOrderId] = useState("");
   const [selectedOrderStatus, setSelectedOrderStatus] = useState(null);
   const [orders, setOrders] = useState([]);
   const [tableData, setTableData] = useState([]);
   const [tableDataOriginal, setTableDataOriginal] = useState([]);
   const [viewing, setViewing] = useState([]);
   const [downloading, setDownloading] = useState([]);
   const [orderQuery, setOrderQuery] = useState({
      destination: null,
      order_id: null,
      orderNumber: null,
      customer_id: null,
      user_id: null,
      terminal_id: null,
      rate_id: null,
      add_date: null,
      startDate: null,
      endDate: null,
      minRate: null,
      maxRate: null,
      status: null,
      external: true,
   });

   //Header data layout for filtering.
   const headerData = [
      { id: "orderNumber", type: "string" },
      { id: "add_date", type: "date" },
      { id: "terminalName", type: "string" },
      { id: "destination", type: "string" },
      { id: "fsc", type: "string" },
      { id: "cpg_rate", type: "string" },
      { id: "status", type: "string" },
      { id: "exp_date", type: "date" },
   ];

   //Error Handler.
   const handleError = useErrorHandler();

   //Loading React Hook.
   useEffect(() => {
      props.getTerminals();

      setOrderQuery({
         destination: null,
         order_id: null,
         orderNumber: null,
         customer_id: null,
         user_id: props.user._id,
         terminal_id: null,
         rate_id: null,
         add_date: null,
         startDate: null,
         endDate: null,
         minRate: null,
         maxRate: null,
         status: null,
         external: true,
      });
   }, []);

   //React Hook for when the order query changes.
   useEffect(() => {
      if (orderQuery.user_id !== null) {
         setTableData([]);
         setLoading(true);
         getOrder(orderQuery);
      }
   }, [orderQuery]);

   //React Hook for when the order array changes.
   useEffect(() => {
      async function createTableList() {
         var newOrderList = [];
         var date_now = new Date();

         await _.forEach(orders, async (order) => {
            await _.forEach(order.items, async (item) => {
               var newOrder = _.cloneDeep(item);

               var exp_date = new Date(order.add_date);
               exp_date.setDate(exp_date.getDate() + 60);

               newOrder.id = _handleObjectProperty(item, "_id") ? item._id : 0;
               newOrder.orderId = _handleObjectProperty(order, "_id") ? order._id : 0;
               newOrder.orderNumber = _handleObjectProperty(order, "orderNumber") ? order.orderNumber : "";
               newOrder.add_date = _handleObjectProperty(order, "add_date") ? order.add_date : null;
               newOrder.terminalName = _handleObjectProperty(item, "terminalName") ? item.terminalName : "";
               newOrder.destination = _handleObjectProperty(item.rate.destination, "city")
                  ? item.rate.destination.city
                  : "";
               newOrder.destination += _handleObjectProperty(item.rate.destination, "state")
                  ? ", " + item.rate.destination.state
                  : "";

               newOrder.fsc = `$${Number.parseFloat(getRateFuelAmount(item)).toFixed(2)} ${
                  _handleObjectProperty(item.fuelTariff.tariffInfo, "ppr")
                     ? `(${
                          item.fuelTariff.overUnder
                             ? item.fuelTariff.overUnder.ppr
                                ? item.fuelTariff.overUnder.ppr.toFixed(2)
                                : item.fuelTariff.tariffInfo.ppr.toFixed(2)
                             : item.fuelTariff.tariffInfo.ppr.toFixed(2)
                       }%)`
                     : `($${
                          item.fuelTariff.overUnder
                             ? item.fuelTariff.overUnder.cpm
                                ? item.fuelTariff.overUnder.cpm.toFixed(2)
                                : item.fuelTariff.tariffInfo.cpm.toFixed(2)
                             : item.fuelTariff.tariffInfo.cpm.toFixed(2)
                       }/mi)`
               }`;

               newOrder.cpg_rate =
                  "$" +
                  (item.rate.rateInfo
                     ? item.rate.rateInfo.base_rate
                        ? calcCPGrate(
                             item.rate.rateInfo.base_rate,
                             item.rate.rateInfo.tolls,
                             item.rate.rateInfo.bobtail,
                          )
                        : 0
                     : 0
                  ).toFixed(2);
               newOrder.status = isOlderThan90Days(order.add_date) ? "expired" : "active";
               newOrder.exp_date = exp_date;

               await newOrderList.push(newOrder);
            });
         });

         newOrderList = _.uniqBy(newOrderList, "id");

         setTableData(newOrderList);
         setTableDataOriginal(newOrderList);

         if (newOrderList.length === 0) {
            setLoading(false);
         }
      }

      //call the above function
      createTableList();
   }, [orders]);

   //React Hook for setting loading off once setting the table data.
   useEffect(() => {
      if (loading && tableData.length > 0) {
         setLoading(false);
      }
   }, [tableData]);

   function isOlderThan90Days(created) {
      const today = new Date().getTime();
      const createdDate = new Date(created).getTime();
      const monthInMiliseconds = 7776000000;
      return today - createdDate > monthInMiliseconds;
   }

   //Get orders based on query.
   function getOrder(orderQuery) {
      orderApi
         .getOrders(orderQuery, null, true)
         .then((resp) => {
            setOrders(resp.orders);
         })
         .catch((err) => {
            openAlertMessage(`Error Loading Quotes: ${err}`, "error");
         });
   }

   //data scrubbing function to return string values for display in table
   const dateFormatter = (value) => {
      try {
         var dateString = value;
         var newDate = new Date(dateString);
         const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(newDate);
         const month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(newDate);
         const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(newDate);
         return month + "/" + day + "/" + year;
      } catch {
         return "00/00/0000";
      }
   };

   const inputProps = {
      step: 0.01,
   };

   //Calculate CPG rates
   const calcCPGrate = (base_rate, tolls, bobtail) => {
      try {
         return +(base_rate ? base_rate : 0) + +(tolls ? tolls : 0) + +(bobtail ? bobtail : 0);
      } catch (err) {
         handleError(err);
      }
   };

   //Format the destination for filtering.
   function onDestinationChange(event, values, index) {
      try {
         //need to check to see how many array items were returned in google api results
         var destination = {
            city: null,
            state: null,
            zip: null,
            place_id: null,
         };

         if (values) {
            //input defaults
            destination.city = values.terms[0].value;

            if (
               values.terms[1].value.length > 2 &&
               values.terms[1].value.toUpperCase() !== "USA" &&
               values.terms[1].value.toUpperCase() !== "US"
            ) {
               //See if terms[1] is state or space in city name.
               let state = _.filter(stateList, (x) => x.name.toUpperCase() === values.terms[1].value.toUpperCase());
               let abbreviation = _.filter(
                  stateList,
                  (x) => x.abbreviation.toUpperCase() === values.terms[1].value.toUpperCase(),
               );

               if (state.length > 0 || abbreviation.length > 0) {
                  if (state.length > 0) {
                     destination.state = state[0].abbreviation;

                     if (
                        values.terms[2].value.toUpperCase() !== "USA" &&
                        values.terms[2].value.toUpperCase() !== "US"
                     ) {
                        destination.zip = values.terms[2].value;
                     } else if (values.terms.length >= 4) {
                        if (
                           values.terms[3].value.toUpperCase() !== "USA" &&
                           values.terms[3].value.toUpperCase() !== "US"
                        ) {
                           destination.zip = values.terms[3].value;
                        }
                     }
                  } else {
                     destination.state = abbreviation[0].abbreviation;

                     if (
                        values.terms[2].value.toUpperCase() !== "USA" &&
                        values.terms[2].value.toUpperCase() !== "US"
                     ) {
                        destination.zip = values.terms[2].value;
                     } else if (values.terms.length >= 4) {
                        if (
                           values.terms[3].value.toUpperCase() !== "USA" &&
                           values.terms[3].value.toUpperCase() !== "US"
                        ) {
                           destination.zip = values.terms[3].value;
                        }
                     }
                  }
               } else {
                  destination.city += " " + values.terms[1].value;

                  if (values.terms[2].value.length > 2) {
                     destination.city += " " + values.terms[2].value;

                     destination.state = values.terms[3].value;

                     if (values.terms.length >= 5) {
                        if (
                           values.terms[4].value.toUpperCase() !== "USA" &&
                           values.terms[4].value.toUpperCase() !== "US"
                        ) {
                           destination.zip = values.terms[4].value;
                        }
                     }
                  } else {
                     destination.state = values.terms[2].value;

                     if (values.terms.length >= 5) {
                        destination.zip = values.terms[3].value;
                     }
                  }
               }
            } else {
               if (values.terms[1].value.toUpperCase() !== "USA" && values.terms[1].value.toUpperCase() !== "US") {
                  destination.state = values.terms[1].value;
               }

               //if searched by zip, then it will appear with 4 items in array, last one always being country
               if (values.terms.length >= 4) {
                  destination.zip = values.terms[2].value;
               }
            }

            destination.place_id = values.place_id;
            var newOrderQuery = _.cloneDeep(orderQuery);
            newOrderQuery.destination = destination;

            setOrderQuery(newOrderQuery);
         } else {
            var newOrderQuery = _.cloneDeep(orderQuery);
            newOrderQuery.destination = null;

            setOrderQuery(newOrderQuery);
         }
      } catch (err) {
         handleError(err);
      }
   }

   //Filter by status.
   function onOrderStatusChange(value) {
      var newOrderQuery = _.cloneDeep(orderQuery);
      newOrderQuery.status = value ? value.status : null;

      setOrderQuery(newOrderQuery);
      setSelectedOrderStatus(value);
   }

   //Open alert.
   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);
   }

   //Close alert.
   function closeAlertMessage() {
      setShowAlert(false);
   }

   //Reset search to get all results.
   function resetSearch() {
      setOrderQuery({
         destination: null,
         order_id: null,
         orderNumber: null,
         customer_id: null,
         user_id: props.user._id,
         terminal_id: null,
         rate_id: null,
         add_date: null,
         startDate: null,
         endDate: null,
         minRate: null,
         maxRate: null,
         status: null,
         external: true,
      });
   }

   //View PDF based on row clicked.
   function viewPDF(params) {
      var finalViewing = [...viewing];
      finalViewing.push(params.row.orderId);
      setViewing(finalViewing);

      //get pdf and open new window to view it
      orderApi
         .getRateSheetPortal(params.row.orderId, null)
         .then((response) => {
            if (response && response.ok === true) {
               return response.arrayBuffer().then((buffer) => {
                  const blob = new Blob([buffer], { type: "application/pdf" });
                  window.open(URL.createObjectURL(blob));
                  setButtonClicked(false);
                  setSavePreviewLoading(false);

                  const index = finalViewing.indexOf(params.row.orderId);

                  if (index > -1) {
                     finalViewing = [...finalViewing.slice(index, -1)];
                  }

                  setViewing(finalViewing);
               });
            } else {
               props.openAlertMessage(
                  "Error loading PDF rate sheet. Please contact your administrator for assistance.",
                  "error",
               );

               const index = finalViewing.indexOf(params.row.orderId);

               if (index > -1) {
                  finalViewing = [...finalViewing.slice(index, -1)];
               }

               setViewing(finalViewing);
            }
         })
         .catch((err) => {
            console.error(err);
            props.openAlertMessage(
               "Error loading PDF rate sheet. Please contact your administrator for assistance.",
               "error",
            );

            const index = finalViewing.indexOf(params.row.orderId);

            if (index > -1) {
               finalViewing = [...finalViewing.slice(index, -1)];
            }

            setViewing(finalViewing);
         });
   }

   //Download PDF based on row clicked.
   function downloadPDF(params) {
      var finalDownloading = [...downloading];
      finalDownloading.push(params.row.orderId);
      setDownloading(finalDownloading);

      //get pdf and open new window to view it
      orderApi
         .getRateSheetPortal(params.row.orderId, null)
         .then((response) => {
            if (response && response.ok === true) {
               return response.arrayBuffer().then((buffer) => {
                  const blob = new Blob([buffer], { type: "application/pdf" });
                  saveAs(blob, `${params.row.orderNumber}.pdf`);

                  const index = finalDownloading.indexOf(params.row.orderId);

                  if (index > -1) {
                     finalDownloading = [...finalDownloading.slice(index, -1)];
                  }

                  setDownloading(finalDownloading);
               });
            } else {
               props.openAlertMessage(
                  "Error loading PDF rate sheet. Please contact your administrator for assistance.",
                  "error",
               );

               const index = finalDownloading.indexOf(params.row.orderId);

               if (index > -1) {
                  finalDownloading = [...finalDownloading.slice(index, -1)];
               }

               setDownloading(finalDownloading);
            }
         })
         .catch((err) => {
            props.openAlertMessage(
               "Error loading PDF rate sheet. Please contact your administrator for assistance.",
               "error",
            );

            const index = finalDownloading.indexOf(params.row.orderId);

            if (index > -1) {
               finalDownloading = [...finalDownloading.slice(index, -1)];
            }

            setDownloading(finalDownloading);
         });
   }

   //Open email modal based on row clicked.
   function emailPDF(params) {
      setOrderId(params.row.orderId);
      setOrderNumber(params.row.orderNumber);
      setRateEmailModal(true);
   }

   //Close email modal.
   function closeRateEmail() {
      setRateEmailModal(!rateEmailModal);
   }

   //Stagger the filter until user is done typing.
   function setSearchTimer(event, searchValue = null) {
      clearTimeout(searchTimer);

      if (event.target.value) {
         setLoading(true);
         searchTimer = setTimeout(function () {
            onChangeFilter(event, searchValue);
         }, 500);
      } else {
         setTableData([]);
         setLoading(true);

         //Force a refresh of orders.
         setOrderQuery({
            destination: null,
            order_id: null,
            orderNumber: null,
            customer_id: null,
            user_id: props.user._id,
            terminal_id: null,
            rate_id: null,
            add_date: null,
            startDate: null,
            endDate: null,
            minRate: null,
            maxRate: null,
            status: null,
            external: true,
         });
      }
   }

   //Filter based on filter text field.
   function onChangeFilter(event, searchValue = null) {
      try {
         //create blank array for return
         var filteredObjects = [];

         //this turns values into lowercase strings
         var regValue = new RegExp(searchValue ? searchValue.toLowerCase() : event.target.value.toLowerCase(), "g");

         //loop through header data since it contains the field names we need to check
         headerData.map((header) => {
            var filter = [];
            //we can only do this on strings, so make sure the type is filled in via the parent component
            if (header.type === "string") {
               //fill the filter on the original table data that was passed in from parent
               filter = tableDataOriginal.filter((filt) => {
                  //return a match where the filter index is the name of the header id
                  return filt[header.id].toLowerCase().match(regValue);
               });
            } else if (header.type === "date") {
               //fill the filter on the original table data that was passed in from parent
               filter = tableDataOriginal.filter((filt) => {
                  //return a match where the filter index is the name of the header id
                  return dateFormatter(filt[header.id].toString()).match(regValue);
               });
            } else {
               //fill the filter on the original table data that was passed in from parent
               filter = tableDataOriginal.filter(
                  (filt) => filt[header.id === (searchValue ? searchValue : event.target.value)],
               );
            }
            //check to see if it returned objects
            if (filter.length > 0) {
               //concat merges arrays since we are adding data from one array to another.  Push only adds a single item/object to an existing array
               filteredObjects = filteredObjects.concat(filter);
            }
         });

         //remove duplicates
         filteredObjects = _.uniqBy(filteredObjects, "_id");

         //set the hook with the newly filtered data and it will render
         if (filteredObjects.length > 0) {
            setTableData(filteredObjects);
         } else {
            setLoading(false);
            setTableData([]);
         }
      } catch (err) {
         handleError(err);
      }
   }

   const classes = useStyles();

   return (
      <>
         <div style={{ position: "sticky", zIndex: "4" }}>
            <div className={classes.secondaryHeader}>
               <Grid
                  xs={12}
                  item
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  style={{ height: "50px" }}
               >
                  <Grid item xs={7} style={{ height: "50px" }}>
                     <h2 style={{ paddingLeft: "1.5rem", marginTop: "10px", fontWeight: "500" }}>Quote History</h2>
                  </Grid>
                  <Grid item xs={3}>
                     <Grid container item xs={12} spacing={1} alignItems="flex-end">
                        <Grid item xs={12}>
                           <TextField
                              id="tf-search"
                              label="Search..."
                              size="small"
                              variant="outlined"
                              onChange={(event) => setSearchTimer(event, event.target.value)}
                              style={{ width: "100%", backgroundColor: "white" }}
                              InputProps={{
                                 endAdornment: (
                                    <InputAdornment position="end">
                                       <SearchIcon />
                                    </InputAdornment>
                                 ),
                              }}
                           />
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item xs={2}>
                     <IconButton
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginLeft: "1rem" }}
                        onClick={() => setOpenDrawer(!openDrawer)}
                     >
                        <MenuIcon style={{ fontSize: "2rem" }} />
                     </IconButton>
                  </Grid>
               </Grid>
               <Drawer anchor={"right"} open={openDrawer} onClose={() => setOpenDrawer(false)}>
                  <div style={{ backgroundColor: "#002D72" }}>
                     <h3 style={{ marginLeft: "1rem", fontWeight: "700", color: "white" }}>Menu</h3>
                  </div>
                  <List className={classes.listDrawer}>
                     <ListItem style={{ height: "1rem" }}>
                        <h6>Terminal</h6>
                     </ListItem>
                     <ListItem style={{ display: "list-item" }}>
                        <Autocomplete
                           id="combo-box-terminal"
                           options={_.filter(props.terminals, (x) => x.active === true)}
                           value={_.find(props.terminals, (x) => x._id === orderQuery.terminal_id)}
                           getOptionLabel={(option) => option.name}
                           onChange={(event, value) =>
                              setOrderQuery({ ...orderQuery, terminal_id: value ? value._id : null })
                           }
                           renderInput={(params) => (
                              <TextField {...params} label="Select Terminal.." variant="outlined" />
                           )}
                           size="small"
                        />
                     </ListItem>
                     <Divider />
                     <ListItem style={{ height: "2rem" }}>
                        <h6>Destination</h6>
                     </ListItem>
                     <ListItem style={{ display: "list-item" }}>
                        <PlaceSearch
                           destination={
                              orderQuery.destination
                                 ? orderQuery.destination.city + ", " + orderQuery.destination.state
                                 : null
                           }
                           onChange={onDestinationChange}
                        />
                     </ListItem>
                     <Divider />
                     <ListItem style={{ height: "2rem" }}>
                        <h6>Status</h6>
                     </ListItem>
                     <ListItem style={{ display: "list-item" }}>
                        <Autocomplete
                           id="combo-box-status"
                           options={[{ status: "ACTIVE" }, { status: "EXPIRED" }]} //These are hardset.
                           onChange={(e, value) => onOrderStatusChange(value)}
                           value={selectedOrderStatus}
                           getOptionLabel={(option) => {
                              return option.status;
                           }}
                           renderInput={(params) => (
                              <TextField {...params} label="Select Status.." variant="outlined" />
                           )}
                           size="small"
                        />
                     </ListItem>
                     <Divider />
                     <ListItem style={{ height: "2rem" }}>
                        <h6>Date Range</h6>
                     </ListItem>
                     <ListItem style={{ display: "list-item" }}>
                        <TextField
                           id="startDate"
                           style={{ marginRight: "1rem" }}
                           label="Start Date"
                           type="date"
                           variant="outlined"
                           onChange={(event) =>
                              setOrderQuery({
                                 ...orderQuery,
                                 startDate: event.target.value ? event.target.value : null,
                              })
                           }
                           value={orderQuery.startDate ? orderQuery.startDate : ""}
                           InputLabelProps={{
                              shrink: true,
                           }}
                           size="small"
                        />
                        <TextField
                           id="endDate"
                           label="End Date"
                           type="date"
                           variant="outlined"
                           onChange={(event) =>
                              setOrderQuery({ ...orderQuery, endDate: event.target.value ? event.target.value : null })
                           }
                           value={orderQuery.endDate ? orderQuery.endDate : ""}
                           InputLabelProps={{
                              shrink: true,
                           }}
                           size="small"
                        />
                     </ListItem>
                     <Divider />
                     <ListItem style={{ height: "2rem" }}>
                        <h6>Rate Range</h6>
                     </ListItem>
                     <ListItem style={{ display: "list-item" }}>
                        <TextField
                           type="number"
                           variant="outlined"
                           id="MinRate"
                           label="Min Rate"
                           value={orderQuery.minRate ? orderQuery.minRate : ""}
                           style={{ marginBottom: "1rem", marginRight: "1rem" }}
                           onChange={(event) =>
                              setOrderQuery({ ...orderQuery, minRate: event.target.value ? event.target.value : null })
                           }
                           inputProps={inputProps}
                           InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                           }}
                           size="small"
                        />
                        <TextField
                           type="number"
                           variant="outlined"
                           id="MaxRate"
                           label="Max Rate"
                           value={orderQuery.maxRate ? orderQuery.maxRate : ""}
                           inputProps={inputProps}
                           InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                           }}
                           onChange={(event) =>
                              setOrderQuery({ ...orderQuery, maxRate: event.target.value ? event.target.value : null })
                           }
                           size="small"
                        />
                     </ListItem>
                     <Divider />
                     <ListItem style={{ justifyContent: "center" }}>
                        <div>
                           <Button
                              style={{ marginRight: "1rem" }}
                              variant="contained"
                              color="primary"
                              onClick={resetSearch}
                           >
                              <RotateLeftIcon />
                              Reset
                           </Button>
                        </div>
                     </ListItem>
                  </List>
               </Drawer>
            </div>
            <Collapse in={showAlert}>
               <Alert
                  variant="filled"
                  severity={alertType ? alertType : "success"}
                  action={
                     <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                           {
                              closeAlertMessage();
                           }
                        }}
                     >
                        <CloseIcon fontSize="inherit" />
                     </IconButton>
                  }
               >
                  {alertMessage}
               </Alert>
            </Collapse>
         </div>
         <Paper
            elevation={3}
            className={classes.root}
            style={{ overflow: "auto", zIndex: 1, width: "calc(100% - ${drawerWidth}px)", marginLeft: "240px" }}
         >
            <Grid container direction={"row"} justify="space-between" alignItems="center">
               <DataGrid
                  className={classes.colCellTitle}
                  loading={loading}
                  columns={[
                     {
                        field: "orderNumber",
                        headerName: "Quote #",
                        description: "The quote number.",
                        type: "string",
                        width: 150,
                        filterable: false,
                     },
                     {
                        field: "add_date",
                        headerName: "Date Created",
                        description: "The date the quote was generated.",
                        type: "date",
                        width: 150,
                        valueFormatter: ({ value }) => dateFormatter(value),
                        filterable: false,
                     },
                     {
                        field: "terminalName",
                        headerName: "Terminal",
                        description: "The terminal name of origin.",
                        type: "string",
                        width: 320,
                        filterable: false,
                     },
                     {
                        field: "destination",
                        headerName: "Destination",
                        description: "The city and state of the destination.",
                        type: "string",
                        width: 315,
                        filterable: false,
                     },
                     {
                        field: "cpg_rate",
                        headerName: "CPG Rate",
                        description: "The CPG rate for the shipment.",
                        type: "string",
                        width: 130,
                        filterable: false,
                     },
                     {
                        field: "fsc",
                        headerName: "FSC",
                        description: "The FSC rate either in DPM($) or PPR(%).",
                        type: "string",
                        width: 115,
                        filterable: false,
                     },
                     {
                        field: "status",
                        headerName: "Status",
                        description: "Quote is either completed or expired.",
                        type: "string",
                        width: 110,
                        filterable: false,
                        renderCell: (params) => (
                           <div
                              style={{
                                 width: "100%",
                                 display: "flex",
                                 justifyContent: "flex-start",
                                 alignItems: "center",
                              }}
                              className={params.row.status === "expired" ? classes.expiredBadge : classes.activeBadge}
                           >
                              <FiberManualRecordIcon />
                              <Typography variant="span">
                                 {params.row.status === "expired" ? "EXPIRED" : "ACTIVE"}
                              </Typography>
                           </div>
                        ),
                     },
                     {
                        field: "exp_date",
                        headerName: "Expire Date",
                        description: "The date when the quote is set to expire.",
                        width: 138,
                        type: "date",
                        valueFormatter: ({ value }) => dateFormatter(value),
                        filterable: false,
                     },
                     {
                        field: "_id",
                        headerName: " ",
                        type: "string",
                        width: 195,
                        sortable: false,
                        filterable: false,
                        renderCell: (params) => (
                           <div>
                              <ButtonGroup>
                                 <Button
                                    classes={{ root: classes.viewButton, label: classes.label }}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={() => viewPDF(params)}
                                    disabled={viewing.includes(params.row.orderId)}
                                 >
                                    {viewing.includes(params.row.orderId) ? (
                                       <CircularProgress size={24} className={classes.buttonProgress} />
                                    ) : (
                                       <PageviewIcon className={classes.icon} />
                                    )}
                                    View
                                 </Button>
                                 <Button
                                    classes={{ root: classes.downloadButton, label: classes.label }}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={() => downloadPDF(params)}
                                    disabled={downloading.includes(params.row.orderId)}
                                 >
                                    {downloading.includes(params.row.orderId) ? (
                                       <CircularProgress size={24} className={classes.buttonProgress} />
                                    ) : (
                                       <GetAppIcon className={classes.icon} />
                                    )}
                                    Download
                                 </Button>
                                 <Button
                                    classes={{ root: classes.emailButton, label: classes.label }}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={() => emailPDF(params)}
                                 >
                                    <EmailIcon className={classes.icon} />
                                    Email
                                 </Button>
                              </ButtonGroup>
                           </div>
                        ),
                     },
                  ]}
                  rows={tableData ? tableData : null}
               ></DataGrid>
            </Grid>
         </Paper>
         <RateEmail
            open={rateEmailModal}
            handleClose={() => closeRateEmail()}
            orderNumber={orderNumber}
            orderId={orderId}
            userId={props.user._id}
            userEmail={props.user.email}
         />
      </>
   );
};

function mapStateToProps(state, ownProps) {
   return {
      terminals: state.terminal.terminals || [],
      user: state.user.currentUser,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getTerminals: () => dispatch(terminalActions.getTerminals()),
   };
}

QuoteHistory.propTypes = {
   terminals: PropTypes.array.isRequired,
   user: PropTypes.object.isRequired,
   getTerminals: PropTypes.func.isRequired,
   openAlertMessage: PropTypes.func.isRequired,
};

QuoteHistory.defaultProps = {
   terminals: [],
   user: {},
   getTerminals: () => {
      return;
   },
   openAlertMessage: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(QuoteHistory);
