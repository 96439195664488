import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
   contentMessage: {
      textAlign: "justify",
      padding: "8px",
      margin: "10px 0px",
      borderLeft: "4px solid #002d72",
      backgroundColor: "#f2f2f2",
      borderRadius: "6px",
      boxShadow:
         "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
      "&:hover": {
         backgroundColor: "#FFFFFF",
      },
   },
   dialog: {
      "&.MuiDialog-container .MuiDialog-paper": {
         minWidth: "400px",
      },
      "&.MuiDialogActionsRoot": {
         padding: "8px 18px 16px 18px",
      },
      backdropFilter: "blur(5px)",
   },
   title: {
      "&.MuiDialogTitle-root": {
         backgroundColor: "#002d72",
         color: "#FFFFFF",
         "& .MuiTypography-root": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
         },
      },
      "& .MuiSvgIcon-root": {
         marginRight: "16px",
      },
      "& .MuiSvgIcon-root": {
         marginRight: "16px",
         animation: "$intermittent 2s infinite ease-in-out",
      },
   },
   container: {
      "&.MuiDialogContent-root": {
         maxHeight: "250px",
      },
   },
   actions: {
      /* Button */
      "&.MuiButtonBase-root.MuiButton-root": {
         backgroundColor: "#002d72",
         color: "#ffffff",
         padding: "8px 12px",
      },
      "&.MuiButtonBase-root.MuiButton-root:hover": {
         backgroundColor: "#063987",
         color: "#ffffff",
      },
      ".MuiButtonBase-root.Mui-disabled": {
         backgroundColor: "#e0e0e0",
      },
   },
   button: {
      backgroundColor: "#002D72",
      color: "#FFFFFF",
      padding: "8px 12px",
      "&:hover": {
         backgroundColor: "#063987",
         color: "#FFFFFF",
      },
      "&.Mui-disabled": {
         backgroundColor: "#e0e0e0",
      },
   },
   "@keyframes intermittent": {
      "0%": {
         opacity: 0,
      },
      "10%": {
         opacity: 0.2,
      },
      "20%": {
         opacity: 0.4,
      },
      "30%": {
         opacity: 0.6,
      },
      "40%": {
         opacity: 0.8,
      },
      "50%": {
         opacity: 1.0,
      },
      "60%": {
         opacity: 0.8,
      },
      "70%": {
         opacity: 0.6,
      },
      "80%": {
         opacity: 0.4,
      },
      "90%": {
         opacity: 0.2,
      },
      "100%": {
         opacity: 0,
      },
   },
});

export default useStyles;
