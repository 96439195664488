import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import { green, red } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import * as orderApi from "../../api/orderApi";

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
   wrapper: {
      margin: theme.spacing(1),
      position: "relative",
   },
   buttonProgress: {
      color: green[500],
   },
   checkIcon: {
      color: red[500],
   },
   button: {
      color: "white",
      backgroundColor: "#002D72",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
   },
   label: {
      flexDirection: "column",
      fontSize: "11px",
   },
   icon: {
      fontSize: "24px",
      marginBottom: theme.spacing.unit,
   },
   textField: {
      [`& fieldset`]: {
         borderTopRightRadius: 0,
         borderBottomRightRadius: 0,
      },
   },
   buttonSend: {
      color: "white",
      backgroundColor: "#002D72",
   },
   labelReset: {
      flexDirection: "column",
      fontSize: "11px",
   },
}));

const RateEmail = (props) => {
   const classes = useStyles();
   var handleError = useErrorHandler();

   let [emailAddresses, setEmailAddresses] = useState([]);
   let [emailAddress, setEmailAddress] = useState("");
   let [invalidEmail, setInvalidEmail] = useState("Enter an Email");
   let [emailingPDF, setEmailingPDF] = useState(false);
   let [alertMessage, setAlertMessage] = useState(null);
   let [alertType, setAlertType] = useState("");

   //Reset all variables on load.
   useEffect(() => {
      setAlertType("");
      setAlertMessage(null);
      setEmailAddresses([]);
      setEmailAddress("");
      setInvalidEmail("Enter an Email");

      if (props.open === true) {
         handleAddEmail(props.userEmail);
      }
   }, [props.open]);

   function handleAddEmail(email) {
      try {
         emailAddresses.push(email);
         setEmailAddress("");
         setInvalidEmail("Enter an Email");
         setEmailAddresses(emailAddresses);
      } catch (err) {
         handleError(err);
      }
   }

   function handleDeleteEmail(chip) {
      try {
         setEmailAddresses(emailAddresses.filter((c) => c !== chip));
      } catch (err) {
         handleError(err);
      }
   }

   function validateEmailAddress(email) {
      try {
         let topLevelDomain = "";

         if (email === null || email === "") {
            setInvalidEmail("Enter an Email");
            setEmailAddress(email);
            return;
         }

         if ((email.match(new RegExp("@", "g")) || []).length === 0) {
            setInvalidEmail(`Invalid Email. "@" Character is Required`);
            setEmailAddress(email);
            return;
         }

         if ((email.match(new RegExp("@", "g")) || []).length > 1) {
            setInvalidEmail(`Invalid Email. Only One "@" Character Allowed`);
            setEmailAddress(email);
            return;
         }

         if ((email.match(new RegExp("[.]", "g")) || []).length === 0) {
            setInvalidEmail(`Invalid Email. "." Character is Required`);
            setEmailAddress(email);
            return;
         }

         if ((email.match(new RegExp(`["(),:;<>]`, "g")) || []).length > 0) {
            setInvalidEmail(`Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`);
            setEmailAddress(email);
            return;
         }

         if ((email.match(new RegExp(`[[]`, "g")) || []).length > 0) {
            setInvalidEmail(`Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`);
            setEmailAddress(email);
            return;
         }

         if ((email.match(new RegExp(`]`, "g")) || []).length > 0) {
            setInvalidEmail(`Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`);
            setEmailAddress(email);
            return;
         }

         if (email.indexOf(`\\`) >= 0) {
            setInvalidEmail(`Invalid Email. Special characters "(),:;<>[\\] Are Not Allowed`);
            setEmailAddress(email);
            return;
         }

         if (email.substring(0, 1) === "@") {
            setInvalidEmail(`Invalid Email. Recipient Name is Required. Email Cannot Begin With @ Character`);
            setEmailAddress(email);
            return;
         }

         if ((email.substring(email.indexOf("@")).match(new RegExp("[.]", "g")) || []).length === 0) {
            setInvalidEmail(`Invalid Email. Top Level Domain (.com, .net, .org, etc) Required`);
            setEmailAddress(email);
            return;
         }

         topLevelDomain = email.substring(email.indexOf("@"));

         if (topLevelDomain.indexOf(".") === 1) {
            setInvalidEmail(`Invalid Email. Domain Name (@gmail, @yahoo, @outlook) Required`);
            setEmailAddress(email);
            return;
         }

         topLevelDomain = topLevelDomain.substring(topLevelDomain.indexOf(".") + 1);

         if (topLevelDomain === "" || topLevelDomain === null) {
            setInvalidEmail(`Invalid Email. Top Level Domain (.com, .net, .org, etc) Required`);
            setEmailAddress(email);
            return;
         }

         let emailExists = emailAddresses.includes(email);

         if (emailExists) {
            setInvalidEmail(`Invalid Email. Email Already Exists to Send`);
            setEmailAddress(email);
            return;
         }

         setInvalidEmail(null);
         setEmailAddress(email);
      } catch (err) {
         handleError(err);
      }
   }

   const emailPDF = () => {
      if (emailAddresses.length === 0) {
         setAlertType("warning");
         setAlertMessage("You must enter at least one email address.");
         return;
      }

      setEmailingPDF(true);

      //emailAddresses should be filled in from your hook
      orderApi
         .sendRateSheetEmailPortal(emailAddresses, props.orderId, null)
         .then((resp) => {
            setAlertType("success");
            setAlertMessage("Sent Email to addresses.");
            setEmailingPDF(false);
         })
         .catch((err) => {
            setAlertType("error");
            setAlertMessage(`Error sending email: ${err}.`);
            setEmailingPDF(false);
         });
   };

   return (
      <Dialog open={props.open} TransitionComponent={Transition} onClose={props.handleClose} fullWidth maxWidth={"xs"}>
         <DialogTitle style={{ backgroundColor: "#F2F2F2", color: "#6F6F6F", textAlign: "center" }}>
            Email Quote#: {props.orderNumber}
         </DialogTitle>
         <Collapse in={alertMessage ? true : false}>
            <Alert
               variant="filled"
               severity={alertType}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setAlertMessage(null);
                     }}
                  >
                     <CloseIcon style={{ fontSize: "2rem" }} />
                  </IconButton>
               }
            >
               {alertMessage}
            </Alert>
         </Collapse>
         <DialogContent style={{ marginTop: "1.5rem" }}>
            <Grid container direction={"row"} xs={12} style={{ placeContent: "space-evenly", margin: "auto" }}>
               <Grid item xs={10}>
                  <TextField
                     id="ci-email"
                     style={{ width: "100%" }}
                     className={classes.textField}
                     value={emailAddress}
                     onChange={(event) => validateEmailAddress(event.target.value ? event.target.value : null)}
                     label="Email"
                     variant="outlined"
                     InputProps={
                        invalidEmail
                           ? {
                                startAdornment: (
                                   <Tooltip title={invalidEmail} arrow>
                                      <InputAdornment position="start">
                                         <CloseIcon className={classes.checkIcon} />
                                      </InputAdornment>
                                   </Tooltip>
                                ),
                             }
                           : {
                                startAdornment: (
                                   <Tooltip title={"Valid Email"} arrow>
                                      <InputAdornment position="start">
                                         <CheckIcon className={classes.buttonProgress} />
                                      </InputAdornment>
                                   </Tooltip>
                                ),
                             }
                     }
                  />
               </Grid>
               <Grid item xs={2}>
                  <Button
                     style={{ width: "100%" }}
                     classes={{ root: classes.button, label: classes.label }}
                     variant="contained"
                     color="primary"
                     disabled={invalidEmail ? true : false}
                     onClick={() => handleAddEmail(emailAddress)}
                  >
                     <AddIcon />
                     Add
                  </Button>
               </Grid>
            </Grid>
            <Grid container direction={"row"} xs={12} style={{ placeContent: "space-evenly", margin: "auto" }}>
               <Grid item xs={12}>
                  {emailAddresses.map((email, index) => {
                     return (
                        <Grid key={`gi-${index}`} item>
                           <Chip
                              style={{ marginTop: "1rem" }}
                              label={email}
                              id="ci-emailAddresses"
                              color="primary"
                              onDelete={() => handleDeleteEmail(email)}
                              size="small"
                           />
                        </Grid>
                     );
                  })}
               </Grid>
            </Grid>
            <Grid container direction={"row"} xs={12} style={{ margin: "auto", paddingTop: "2%" }}>
               <Grid item xs={12}>
                  <ButtonGroup style={{ float: "right" }}>
                     <Button
                        classes={{ root: classes.buttonSend, label: classes.label }}
                        variant="contained"
                        color="primary"
                        disabled={emailingPDF}
                        onClick={() => emailPDF()}
                     >
                        {emailingPDF ? (
                           <CircularProgress size={24} className={classes.buttonProgress} />
                        ) : (
                           <EmailRoundedIcon className={classes.icon} />
                        )}
                        Send
                     </Button>
                     <Button
                        classes={{ root: classes.buttonReset, label: classes.labelReset }}
                        variant="contained"
                        color="secondary"
                        disabled={emailingPDF}
                        onClick={props.handleClose}
                     >
                        <ExitToAppRoundedIcon className={classes.icon} />
                        Exit
                     </Button>
                  </ButtonGroup>
               </Grid>
            </Grid>
         </DialogContent>
      </Dialog>
   );
};

RateEmail.propTypes = {
   open: PropTypes.boolean,
   userEmail: PropTypes.string.isRequired,
   orderId: PropTypes.string.isRequired,
   orderNumber: PropTypes.string.isRequired,
   handleClose: PropTypes.func.isRequired,
};

RateEmail.defaultProps = {
   open: false,
   userEmail: "",
   orderId: "",
   orderNumber: "",
   handleClose: () => {
      return;
   },
};

export default RateEmail;
