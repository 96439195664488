import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import * as cookieTools from "../../cookieTools";

const SecureRoute = ({ component: Component, ...props }) => {
   //check jwt via cookies first
   if (cookieTools.checkCookie("rate-guide-token")) {
      let token = cookieTools.getCookie("rate-guide-token");
      if (!token) {
         //if token is blank return to login
         return <Route {...props} render={() => <Redirect to="/login" />} />;
      }
   } else {
      //token not found, return to login
      return <Route {...props} render={() => <Redirect to="/login" />} />;
   }

   //if above token validation is good, direct to route
   return <Route path={props.path} render={(props) => <Component {...props} />} />;
};

function mapStateToProps(state, ownProps) {
   return {
      user: state.user.currentUser || [],
   };
}

SecureRoute.propTypes = {
   path: PropTypes.string.isRequired,
   component: PropTypes.any,
};

SecureRoute.defaultProps = {
   path: "",
   component: null,
};

export default connect(mapStateToProps)(SecureRoute);
