import { Avatar, Collapse, Divider, Grid, IconButton, Paper, TextField, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import SaveIcon from "@material-ui/icons/Save";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as azureStorage from "../../common/azureStorage";
import * as cookieTools from "../../cookieTools";
import * as userActions from "../../redux/actions/userActions";
import defaultuser from "../Logo/defaultuser.png";
var _ = require("lodash");
var fileReader = new FileReader();

function Profile(props) {
   const [user, setUser] = useState(props.user);
   let [showAlert, setShowAlert] = useState(false);
   let [alertMessage, setAlertMessage] = useState(null);
   let [alertType, setAlertType] = useState("");

   //avatar hook also acts as array buffer
   const [avatarSrc, setAvatarSrc] = useState(defaultuser);

   const useStyles = makeStyles((theme) => ({
      wrapper: {
         width: "100%",
         minWidth: "100%",
      },
      inner: {
         minWidth: "fit-content",
      },
   }));

   const classes = useStyles();

   useEffect(() => {
      setUser(props.user);

      if (props.avatar) {
         setAvatarSrc(props.avatar);
      }

      return () => {
         setUser(null);
         setAvatarSrc(null);
      };
   }, [props]);

   function onChangeHandler(event) {
      var newUser = _.cloneDeep(user);
      newUser[event.target.name] = event.target.value;
      setUser(newUser);
   }

   async function onChangeFile(evt) {
      //add onload handler for file buffer
      fileReader.onload = async function (event) {
         if (event.target.result !== null) {
            setAvatarSrc(event.target.result);
         }
      };

      //convert to url so it can be displayed on web page
      fileReader.readAsDataURL(evt.target.files[0]);
   }

   function saveUser() {
      try {
         var newUser = _.cloneDeep(user);
         props.saveUser(newUser);

         //call upload to azure blob storage
         azureStorage.uploadAvatarToStorage(avatarSrc, props.user._id);

         let cookieUserID = cookieTools.getCookie("rate-guide-userID");

         props.loadUser(cookieUserID);

         openAlertMessage("Successfully update profile.", "success");
      } catch (err) {
         openAlertMessage("Unable to update profile. Please contact your administrator for assistance.", "error");
      }
   }

   //Open alert message.
   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   }

   //Close alert message
   function closeAlertMessage() {
      setShowAlert(false);
   }

   return (
      <>
         <Paper style={{ width: "70%", height: "100vh", marginRight: "15%", marginLeft: "15%" }}>
            <Collapse in={showAlert}>
               <Alert
                  variant="filled"
                  severity={alertType ? alertType : "success"}
                  action={
                     <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                           {
                              closeAlertMessage();
                           }
                        }}
                     >
                        <CloseIcon fontSize="inherit" />
                     </IconButton>
                  }
               >
                  {alertMessage}
               </Alert>
            </Collapse>
            <div className={classes.wrapper}>
               <div className={classes.inner}>
                  <Grid
                     container
                     direction={"row"}
                     justify="space-between"
                     alignItems="center"
                     style={{ marginTop: "5rem" }}
                  >
                     <div style={{ width: "75%", margin: "0 auto", marginTop: "1rem" }}>
                        <Paper elevation={3} style={{ padding: "3%" }}>
                           <div style={{ textAlignLast: "right" }}>
                              <Tooltip title="Save" arrow>
                                 <IconButton color="primary" onClick={() => saveUser()}>
                                    <SaveIcon></SaveIcon>
                                 </IconButton>
                              </Tooltip>
                           </div>
                           <Divider />
                           <h3
                              style={{
                                 fontFamily: "Helvetica Neue, Helvetica, Arial",
                                 textAlign: "center",
                                 marginBottom: "0",
                              }}
                           >
                              {props.user.email}
                           </h3>
                           <div style={{ display: "flex" }}>
                              <Avatar src={avatarSrc} style={{ width: "7rem", height: "7rem", marginLeft: "3rem" }} />
                              <input
                                 accept="image/*"
                                 style={{ display: "none" }}
                                 id="icon-button-file"
                                 type="file"
                                 onChange={onChangeFile}
                              />
                              <label htmlFor="icon-button-file">
                                 <IconButton color="primary" aria-label="upload picture" component="span">
                                    <PhotoCamera />
                                 </IconButton>
                              </label>
                              <Grid Item style={{ alignSelf: "center", width: "-webkit-fill-available" }}>
                                 <TextField
                                    label="First Name"
                                    name="firstname"
                                    style={{ marginLeft: "3rem" }}
                                    value={user.firstname}
                                    onChange={onChangeHandler}
                                 />
                                 <TextField
                                    label="Last Name"
                                    name="lastname"
                                    style={{ marginLeft: "3rem" }}
                                    value={user.lastname}
                                    onChange={onChangeHandler}
                                 />
                                 <TextField
                                    label="Company"
                                    style={{ marginLeft: "3rem" }}
                                    value={props.customer.name}
                                    InputProps={{
                                       readOnly: true,
                                    }}
                                 />
                              </Grid>
                           </div>
                        </Paper>
                     </div>
                  </Grid>
               </div>
            </div>
         </Paper>
      </>
   );
}

function mapStateToProps(state, ownProps) {
   return {
      user: state.user.currentUser || {},
      customer: state.customer,
      avatar: state.user.avatar,
   };
}

function mapDispatchToProps(dispatch) {
   return {
      saveUser: (user) => dispatch(userActions.saveUserAction(user)),
      loadUser: () => dispatch(userActions.loadUser()),
   };
}

Profile.propTypes = {
   user: PropTypes.object.isRequired,
   customer: PropTypes.object.isRequired,
   avatar: PropTypes.any,
   saveUser: PropTypes.func.isRequired,
   loadUser: PropTypes.func.isRequired,
};

Profile.defaultProps = {
   user: {},
   customer: {},
   avatar: null,
   saveUser: () => {
      return;
   },
   loadUser: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
