export function getCookie(cname) {
   var name = cname + "=";
   var decodedCookie = decodeURIComponent(document.cookie);
   var ca = decodedCookie.split(";");
   for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
         c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
         return c.substring(name.length, c.length);
      }
   }
   return "";
}

export function setCookie(cname, cvalue, exdate) {
   var expires = "expires=" + exdate;
   document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function checkCookie(cname) {
   var cookie = getCookie(cname);
   if (cookie !== "") {
      return true;
   } else {
      return false;
   }
}

export function deleteCookie(cname) {
   document.cookie = cname + "=;expires=" + new Date().toUTCString() + ";path=/";
}

export function getFormattedExpDate() {
   var date = new Date();
   date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
   return date;
}
