import * as cookieTools from "../cookieTools";
import { HandleError, HandleResponse } from "./apiUtils";
const baseUrl = "/api/activity/";

export function getActivity(activityID, service, activity_code, activityOptions) {
   let token = cookieTools.getCookie("rate-guide-token");

   return fetch(baseUrl + "getActivity", {
      method: "post",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
      body: JSON.stringify({ activityID, service, activity_code, activityOptions }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function saveActivity(activity) {
   let token = cookieTools.getCookie("rate-guide-token");

   return fetch(baseUrl + "saveActivity", {
      method: "post",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
      body: JSON.stringify(activity),
   })
      .then(HandleResponse)
      .catch(HandleError);
}
