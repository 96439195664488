import * as cookieTools from "../cookieTools";
import { HandleError, HandleResponse } from "./apiUtils";
const baseUrl = "/api/user/";

export function getUsers(uid = null, custId = null, internalUser = null) {
   let token = cookieTools.getCookie("rate-guide-token");

   let url = baseUrl + "getUser?";

   if (uid) {
      url += "uid=" + uid;
   }

   if (custId) {
      url += (uid ? "&" : "") + "custID=" + custId;
   }

   if (internalUser) {
      url += (uid || custId ? "&" : "") + "internalUser=" + internalUser;
   }

   return fetch(url, {
      method: "get",
      headers: { Authorization: "Bearer " + token },
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function loginUser(user) {
   return fetch(baseUrl + "login", {
      method: "POST",
      body: JSON.stringify({ email: user.email, password: user.password, internal: false }),
      headers: { "Content-Type": "application/json" },
   })
      .then((resp) => resp.json())
      .then((data) => {
         if (data.message) {
            throw Error(data.message);
         } else {
            return data;
         }
      });
}

export function logoutCurrentUser() {
   let token = cookieTools.getCookie("rate-guide-token");

   cookieTools.deleteCookie("rate-guide-token");
   cookieTools.deleteCookie("rate-guide-userID");

   return fetch(baseUrl + "logout", {
      method: "POST",
      headers: { Authorization: "Bearer " + token },
   })
      .then((resp) => resp.json())
      .catch(HandleError);
}

export function submitNewUser(newUser) {
   return fetch(baseUrl + "register", {
      method: "post",
      body: JSON.stringify(newUser),
      headers: { "Content-Type": "application/json" },
   })
      .then((resp) => resp.json())
      .then((data) => {
         return data;
      });
}

export function requestPasswordReset(email) {
   return fetch(baseUrl + "reset", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function resetPassword(uid, token, newPassword) {
   let body = {
      userID: uid,
      token: token,
      newPassword: newPassword,
   };

   return fetch(baseUrl + "resetPassword", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function saveUser(user) {
   let token = cookieTools.getCookie("rate-guide-token");

   return fetch(baseUrl + "saveProfile", {
      method: "POST",
      headers: { "content-type": "application/json", Authorization: "Bearer " + token },
      body: JSON.stringify({ user }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function inviteUser(email) {
   let token = cookieTools.getCookie("rate-guide-token");

   return fetch(baseUrl + "inviteNewUser", {
      method: "POST",
      headers: { "content-type": "application/json", Authorization: "Bearer " + token },
      body: JSON.stringify({ email }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function verifyToken() {
   let token = cookieTools.getCookie("rate-guide-token");

   return fetch(baseUrl, {
      method: "GET",
      headers: { "content-type": "application/json", Authorization: "Bearer " + token },
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function deleteUser(userID) {
   let token = cookieTools.getCookie("rate-guide-token");

   let url = baseUrl + "deleteUser?";

   if (userID) {
      url += "userID=" + userID;
   }

   return fetch(url, {
      method: "delete",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function validateRegistration(email, invToken, customerId) {
   return fetch(baseUrl + "validateRegistration", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
         email,
         invToken,
         customerId,
      }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}
