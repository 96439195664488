import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import PropTypes from "prop-types";
import React from "react";

//create your layout here, then pass components into layout like so:
// <Layout>
//    <LayoutHeader>
//      <Your header buttons and options here />
//        <LayoutDrawer openDrawer={true}>
//           <Your Drawer Items here />
//        </LayoutDrawer>
//    </LayoutHeader
//    <LayoutDrawer>
//      Your drawer buttons and options here />
//    </LayoutDrawer>
//    <LayoutBody>
//      <form>
//        <input />
//      </form>
//    </LayoutBody>
// </Layout>

const useStyles = makeStyles((theme) => ({
   secondaryHeader: {
      borderBottom: "1px solid #2F3136",
      marginLeft: "240px",
      height: "50px",
      zIndex: "3",
      backgroundColor: "#F2F2F2",
      color: "#6F6F6F",
   },
   inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
         width: "20ch",
      },
      buttonReset: {
         width: "100%",
      },
      labelReset: {
         flexDirection: "column",
         fontSize: "9px",
      },
   },
}));

export const LayoutHeader = (props) => {
   const classes = useStyles();

   return (
      <div className={classes.secondaryHeader}>
         <Grid
            xs={12}
            container
            direction="rows"
            justify="space-between"
            alignItems="center"
            style={{ height: "50px" }}
         >
            <Grid item xs={3} style={{ height: "50px" }}>
               <h2 style={{ paddingLeft: "1.5rem", marginTop: "10px", fontWeight: "500" }}>Rate Search</h2>
            </Grid>
            <Grid item xs={8} style={{ height: "50px" }}>
               <Button
                  style={{ marginTop: "8px", marginLeft: "70%" }}
                  classes={{ root: classes.buttonReset, label: classes.labelReset }}
                  variant="contained"
                  color="primary"
                  onClick={props.resetSearch}
               >
                  <RotateLeftIcon className={classes.icon} />
                  Reset
               </Button>
            </Grid>
         </Grid>
      </div>
   );
};

LayoutHeader.propTypes = {
   resetSearch: PropTypes.func.isRequired,
};

LayoutHeader.defaultProps = {
   resetSearch: () => {
      return;
   },
};
