import PropTypes from "prop-types";
import { useCallback, useRef, useState } from "react";
// Material
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
// services
import { setUserMessages } from "../../api/adminMessageApi";
// custom styles
import useStyles from "./styles.jsx";

const getDateFormat = () => {
   const date = new Date();
   const month = date.getMonth() + 1;
   const day = date.getDate();
   const year = date.getFullYear();
   return `${month}/${day}/${year}`;
};

const SIZE_OF_BLOCK_PIXELS = 270;

function AdminMessageDialog({ messages, acknowledgeFcn }) {
   const [today, _] = useState(() => getDateFormat());
   const [isItRead, setIsItRead] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const fullScreen = useMediaQuery("@media (max-width:899.95px)");
   const contentRef = useRef(null);
   const classes = useStyles();

   const divRefCallback = useCallback((node) => {
      if (node !== null) {
         if (!isItRead && node.scrollHeight < 240) {
            setIsItRead(true);
         }
      }
   }, []);

   const readMessage = async () => {
      setIsLoading(true);
      const IDs = messages.map((message) => message._id);
      try {
         const payload = {
            messageIDs: IDs,
         };
         await setUserMessages(payload);
         acknowledgeFcn();
      } catch (error) {
         console.log("error: ", error.message);
      }
      setIsLoading(false);
   };

   const handleAcknowledge = () => {
      readMessage();
   };

   const handleScroll = () => {
      if (!isItRead && contentRef.current.scrollTop > contentRef.current.scrollHeight - SIZE_OF_BLOCK_PIXELS) {
         setIsItRead(true);
      }
   };

   return (
      <Dialog open={true} className={classes.dialog} fullWidth keepMounted fullScreen={fullScreen}>
         <DialogTitle className={classes.title}>
            <NewReleasesIcon />
            What&apos;s New {today}
         </DialogTitle>
         <DialogContent ref={contentRef} onScroll={handleScroll} className={classes.container}>
            <div ref={divRefCallback}>
               {messages?.map((message) => (
                  <div className={classes.contentMessage} key={message._id}>
                     {message.message}
                  </div>
               ))}
            </div>
         </DialogContent>
         <DialogActions className={classes.actions}>
            <Button
               onClick={handleAcknowledge}
               className={classes.button}
               disabled={!isItRead || isLoading}
               startIcon={
                  isLoading ? <CircularProgress size={20} disableShrink /> : isItRead ? <DoneAllIcon /> : <DoneIcon />
               }
            >
               Acknowledge
            </Button>
         </DialogActions>
      </Dialog>
   );
}

AdminMessageDialog.propTypes = {
   messages: PropTypes.array.isRequired,
   acknowledgeFcn: PropTypes.func.isRequired,
};

export default AdminMessageDialog;
