import { Button, CircularProgress, Divider, FormControl, Grid, Paper, TextField } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import React from "react";
import cpgLogo from "../Logo/ContainerPort-Full-Color-Logo-RGB-300dpi.png";

const LoginForm = (props) => {
   return (
      <Grid container direction={"row"} justify="space-between" alignItems="center" style={{ marginTop: "10rem" }}>
         <div style={{ width: "406px", maxWidth: "406px", margin: "0 auto" }}>
            <Collapse in={props.loggedOut}>
               <Alert variant="filled" severity={"warning"}>
                  {"You have been logged out due to inactivity."}
               </Alert>
            </Collapse>
            <Paper elevation={3} style={{ padding: "3%" }}>
               <div>
                  <img src={cpgLogo} style={{ width: "10rem" }} alt="" />
               </div>
               <Divider />
               <h3 style={{ fontFamily: "Helvetica Neue, Helvetica, Arial", textAlign: "center" }}>Login</h3>
               <div style={{ textAlign: "-webkit-center" }}>
                  <FormControl controlid="email" style={{ marginBottom: "1rem", width: "75%" }}>
                     <TextField
                        id="email"
                        type="email"
                        autoFocus
                        value={props.email}
                        onChange={props.handleEmailChange}
                        placeholder="Email"
                        onKeyDown={props.handleEnterKey}
                     />
                  </FormControl>
                  <FormControl controlid="password" style={{ width: "75%" }}>
                     <TextField
                        id="password"
                        type="password"
                        value={props.password}
                        onChange={props.handlePasswordChange}
                        placeholder="Password"
                        onKeyDown={props.handleEnterKey}
                     />
                  </FormControl>
               </div>
               {props.loading ? (
                  <div style={{ marginTop: "2rem", textAlignLast: "center" }}>
                     <Button
                        variant="contained"
                        color="primary"
                        type="text"
                        disabled={true}
                        onClick={props.handleSubmit}
                     >
                        <CircularProgress style={{ width: "20px", height: "20px", marginRight: "5px" }} /> Login{" "}
                     </Button>
                  </div>
               ) : (
                  <div style={{ marginTop: "2rem", textAlignLast: "center" }}>
                     <div style={{ marginBottom: "1rem" }}>
                        <Button
                           variant="contained"
                           color="primary"
                           type="text"
                           disabled={false}
                           onClick={props.handleSubmit}
                        >
                           {" "}
                           Login{" "}
                        </Button>
                     </div>
                     <div>
                        <Button variant="outlined" color="primary" onClick={props.handleReset}>
                           Forgot Password?
                        </Button>
                     </div>
                  </div>
               )}
            </Paper>
         </div>
      </Grid>
   );
};

LoginForm.propTypes = {
   loading: PropTypes.bool.isRequired,
   email: PropTypes.string.isRequired,
   loggedOut: PropTypes.string,
   password: PropTypes.string.isRequired,
   handleEmailChange: PropTypes.func.isRequired,
   handleEnterKey: PropTypes.func.isRequired,
   handlePasswordChange: PropTypes.func.isRequired,
   handleSubmit: PropTypes.func.isRequired,
   handleRegister: PropTypes.func.isRequired,
   handleReset: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
   loading: false,
   email: "",
   loggedOut: "",
   password: "",
   handleEmailChange: () => {
      return;
   },
   handleEnterKey: () => {
      return;
   },
   handlePasswordChange: () => {
      return;
   },
   handleSubmit: () => {
      return;
   },
   handleRegister: () => {
      return;
   },
   handleReset: () => {
      return;
   },
};

export default LoginForm;
