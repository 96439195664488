import { Avatar, Divider, Menu, MenuItem } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as azureStorage from "../../common/azureStorage";
import * as userActions from "../../redux/actions/userActions";
import defaultuser from "../Logo/defaultuser.png";

function ProfileDropdown(props) {
   let [anchorEl, setAnchorEl] = useState(null);
   let [avatar, setAvatar] = useState(defaultuser);

   useEffect(() => {
      azureStorage.getAvatarFromStorage(props.user._id);
      if (props.avatar) {
         setAvatar(props.avatar);
      }
   }, [props.avatar]);

   const handleOpen = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   return (
      <>
         <Tooltip title="Your Profile" arrow>
            <Avatar src={props.avatar ? props.avatar : avatar} onClick={handleOpen} />
         </Tooltip>
         <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            TransitionComponent={Fade}
            style={{ top: "50px" }}
         >
            <MenuItem onClick={() => props.handleClick("/profile")}>Profile</MenuItem>
            <MenuItem onClick={() => props.handleClick("/")}>Search Rates</MenuItem>
            <Divider />
            <MenuItem>About</MenuItem>
            <MenuItem onClick={props.logout}>Logout</MenuItem>
         </Menu>
      </>
   );
}

function mapStateToProps(state, ownProps) {
   return {
      user: state.user.currentUser,
      avatar: state.user.avatar,
   };
}
function mapDispatchToProps(dispatch) {
   return {
      logout: () => dispatch(userActions.logoutCurrentUser()),
   };
}

ProfileDropdown.propTypes = {
   avatar: PropTypes.any,
   user: PropTypes.object.isRequired,
   handleClick: PropTypes.func.isRequired,
   logout: PropTypes.func.isRequired,
};

ProfileDropdown.defaultProps = {
   avatar: null,
   user: {},
   handleClick: () => {
      return;
   },
   logout: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDropdown);
