import { CssBaseline } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { render } from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import App from "./components/App";
import ErrorHandler from "./components/ErrorHandler";
import forgotPassword from "./components/forgotPassword/forgotPassword";
import LoginPage from "./components/login/LoginPage";
import RegistrationPage from "./components/registration/RegistrationPage";
import ResetPasswordPage from "./components/resetPassword/ResetPasswordPage";
import store from "./redux/configureStore";
import "./styles/index.css";

const theme = createTheme({
   palette: {
      primary: {
         main: "#002D72",
      },
      background: {
         default: "#f2f2f2",
      },
      text: {
         primary: "#6F6F6F",
      },
   },
   typography: {
      fontFamily: `"Helvetica", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 600,
      fontWeightMedium: 700,
   },
   overrides: {
      MuiInputBase: {
         input: {
            "&:-webkit-autofill": {
               //This is how we ignore the auto-filling styles from browsers like Chrome!!!!
               transitionDelay: "99999s",
               transitionProperty: "background-color, color",
            },
         },
      },
   },
});

render(
   <ReduxProvider store={store}>
      {/** This error handler is global. We can use it to wrap individual components as well. */}
      <ErrorBoundary FallbackComponent={ErrorHandler}>
         <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
               <Switch>
                  <Route path="/user/register/:id/:email/:customerid" component={RegistrationPage} />
                  <Route path="/user/resetPassword/:jwt/:uid" component={ResetPasswordPage} />
                  <Route path="/login/forgotPassword" component={forgotPassword} />
                  <Route path="/login/:loggedOut?" component={LoginPage} />
                  <Route path="/" component={App} />
               </Switch>
            </Router>
         </ThemeProvider>
      </ErrorBoundary>
   </ReduxProvider>,
   document.getElementById("root"),
);
