import * as cookieTools from "../cookieTools";
import { HandleError, HandleResponse } from "./apiUtils";
const baseUrl = "/api/application/";

export function getTerminals(termID) {
   let token = cookieTools.getCookie("rate-guide-token");
   let url = baseUrl + "getTerminals?";

   if (termID) {
      url += `termID=${termID}`;
   }

   return fetch(url, {
      method: "get",
      headers: { Authorization: "Bearer " + token },
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function saveTerminal(terminal, isNew = false) {
   let token = cookieTools.getCookie("rate-guide-token");

   return fetch(baseUrl + "saveTerminal", {
      method: "post",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
      body: JSON.stringify({ terminal, isNew }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function deleteTerminal(termID) {
   let token = cookieTools.getCookie("rate-guide-token");

   let url = baseUrl + "deleteTerminal?";

   if (termID) {
      url += "termID=" + termID;
   }

   return fetch(url, {
      method: "delete",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function getLookup(code) {
   let token = cookieTools.getCookie("rate-guide-token");

   let url = baseUrl + "getLookup?";

   if (code) {
      url += "code=" + code;
   }

   return fetch(url, {
      method: "get",
      headers: { Authorization: "Bearer " + token },
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function getAlias(aliasID) {
   let token = cookieTools.getCookie("rate-guide-token");
   let url = baseUrl + "getAlias?";

   if (aliasID) {
      url += `aliasID=${aliasID}`;
   }

   return fetch(url, {
      method: "get",
      headers: { Authorization: "Bearer " + token },
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function saveAlias(alias) {
   let token = cookieTools.getCookie("rate-guide-token");

   return fetch(baseUrl + "saveAlias", {
      method: "post",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
      body: JSON.stringify({ alias }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function deleteAlias(aliasID) {
   let token = cookieTools.getCookie("rate-guide-token");

   let url = baseUrl + "deleteAlias?";

   if (aliasID) {
      url += "aliasID=" + aliasID;
   }

   return fetch(url, {
      method: "delete",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function getConnectionString() {
   let token = cookieTools.getCookie("rate-guide-token");

   let url = baseUrl + "getConnectionString";

   return fetch(url, {
      method: "get",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function getAzureShare() {
   let token = cookieTools.getCookie("rate-guide-token");

   let url = baseUrl + "getAzureShare";

   return fetch(url, {
      method: "get",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
   })
      .then(HandleResponse)
      .catch(HandleError);
}
