import * as cookieTools from "../cookieTools";
import { HandleError, HandleResponse } from "./apiUtils";
const baseUrl = "/api/order/";

export class orderQuery {
   constructor(
      destination,
      order_id,
      orderNumber,
      customer_id,
      user_id,
      terminal_id,
      rate_id,
      add_date,
      startDate,
      endDate,
      minRate,
      maxRate,
   ) {
      this.destination = destination;
      this.order_id = order_id;
      this.orderNumber = orderNumber;
      this.customer_id = customer_id;
      this.user_id = user_id;
      this.terminal_id = terminal_id;
      this.rate_id = rate_id;
      this.add_date = add_date;
      this.startDate = startDate;
      this.endDate = endDate;
      this.minRate = minRate;
      this.maxRate = maxRate;
   }
}

export function checkout(orderInfo) {
   let token = cookieTools.getCookie("rate-guide-token");

   return fetch(baseUrl + "checkout", {
      method: "post",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
      body: JSON.stringify({ orderInfo: orderInfo }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function getOrders(orderQuery, page, searchField) {
   let token = cookieTools.getCookie("rate-guide-token");

   return fetch(baseUrl + "getOrders", {
      method: "post",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
      body: JSON.stringify({ orderQuery, page, searchField }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function getRateSheetPortal(order_id, previewOrder) {
   let token = cookieTools.getCookie("rate-guide-token");

   return fetch(baseUrl + "getRateSheetPortal", {
      method: "post",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
      body: JSON.stringify({ order_id, previewOrder }),
   })
      .then((response) => response)
      .catch(HandleError);
}

export function sendRateSheetEmailPortal(emailAddresses, order_id, line_id) {
   let token = cookieTools.getCookie("rate-guide-token");

   return fetch(baseUrl + "emailRateSheetPortal", {
      method: "post",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
      body: JSON.stringify({ emailAddresses, order_id, line_id }),
   })
      .then(HandleResponse)
      .catch(HandleError);
}

export function searchOrders(searchValue) {
   let token = cookieTools.getCookie("rate-guide-token");

   return fetch(baseUrl + "search", {
      method: "post",
      headers: {
         Authorization: "Bearer " + token,
         "Content-Type": "application/json",
      },
      body: JSON.stringify({ searchValue }),
   })
      .then(HandleResponse)
      .catch((err) => console.error(err));
}
