import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "../Logo/Container-Port-White-Logotype.png";
import NotificationsDropdown from "./NotificationsDropdown";
import ProfileDropdown from "./ProfileDropdown";

const useStyles = makeStyles((theme) => ({
   containerStyle: {
      [theme.breakpoints.up("sm")]: {
         width: "100%",
      },
      backgroundColor: "#010440",
      width: "100%",
      maxHeight: "78px",
   },
   title: {
      flexGrow: 1,
      textAlign: "center",
      color: "white",
   },
   menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
         display: "none",
      },
   },
   headerButtons: {
      backgroundColor: "#010440",
      color: "white",
      paddingRight: "5rem",
      paddingLeft: "2rem",
   },
   headerDivider: {
      color: "#010440",
      height: "3.1rem",
   },
   imgStyle: {
      height: "67px",
      padding: "2%",
      marginTop: "5px",
   },
}));

function Header(props) {
   const history = useHistory();
   const classes = useStyles();

   function handleClick(path) {
      history.push(path);
   }

   return (
      <AppBar position="fixed" className={classes.containerStyle}>
         <Toolbar style={{ backgroundColor: "#010440" }}>
            <div>
               <a href="/">
                  <img
                     className={classes.imgStyle}
                     src={require("../Logo/Container-Port-White-Logotype.png")}
                     alt=""
                  ></img>
               </a>
            </div>
            <Typography variant="h6" className={classes.title}>
               {props.content}
            </Typography>
            <div style={{ marginTop: "0.40rem" }}>
               <NotificationsDropdown></NotificationsDropdown>
            </div>
            <div className={classes.headerButtons}>
               <ProfileDropdown handleClick={handleClick}></ProfileDropdown>
            </div>
         </Toolbar>
      </AppBar>
   );
}

function mapStateToProps(state, ownProps) {
   return {
      user: state.user,
      content: ownProps.content,
      setParentState: ownProps.setParentState,
   };
}

Header.propTypes = {
   user: PropTypes.object.isRequired,
   content: PropTypes.string.isRequired,
   handleDrawerToggle: PropTypes.func.isRequired,
};

Header.defaultProps = {
   user: {},
   content: "",
   handleDrawerToggle: () => {
      return;
   },
};

export default connect(mapStateToProps)(Header);
