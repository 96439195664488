import actionTypes from "../actions/actionTypes";

const initialState = {
   customer: {},
};

export default function customerReducer(state = initialState, action) {
   switch (action.type) {
      case actionTypes.GET_CUSTOMERS:
         return action.customer;
      default:
         return state;
   }
}
