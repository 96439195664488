import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { validateRegistration } from "../../api/userApi";
import * as userActions from "../../redux/actions/userActions";
import containerLogo from "../Logo/ContainerPort-Logo-white.png";
import { ExpiredPage } from "./ExpiredPage";
import RegistrationForm from "./RegistrationForm";
var _ = require("lodash");

const RegisterPage = (props) => {
   let [state, setState] = useState({
      confirm: "",
      password: "",
      firstName: "",
      lastName: "",
      invToken: props.match.params.id,
      email: props.match.params.email,
      customerId: props.match.params.customerid ? props.match.params.customerid : null,
   });
   const [validatedToken, setValidatedToken] = useState(true);
   const [loading, setLoading] = useState(true);
   const [isSubmitLoading, setIsSubmitLoading] = useState(false);

   const { email, invToken, customerId } = state;
   useEffect(() => {
      validateRegistration(email, invToken, customerId)
         .then((res) => {
            if (!res.success) {
               setValidatedToken(false);
               setLoading(false);
               return;
            }
            return;
         })
         .catch((err) => {
            setValidatedToken(false);
         });
      setLoading(false);
   }, []);

   let [error, setError] = useState("");
   let [success, setSuccess] = useState(false);
   let [policy, setPolicy] = useState({
      policy1: false,
      policy2: false,
      policy3: false,
      policy4: false,
   });

   function checkPolicy(password) {
      var newPolicy = _.cloneDeep(policy);
      var lowerCase = new RegExp(/[a-z]/);
      var upperCase = new RegExp(/[A-Z]/);
      var specialChar = new RegExp(/[!@#$%^&*)(+=._-]/);
      var minChar = new RegExp(/.{8,}/);
      var minNum = new RegExp(/(?=.*?[0-9])/);

      //do password validate for policy 1 then set the object true or false
      if (password.match(lowerCase) && password.match(upperCase)) {
         newPolicy.policy1 = true;
      } else {
         newPolicy.policy1 = false;
      }

      if (password.match(minNum)) {
         newPolicy.policy2 = true;
      } else {
         newPolicy.policy2 = false;
      }

      if (password.match(specialChar)) {
         newPolicy.policy3 = true;
      } else {
         newPolicy.policy3 = false;
      }

      if (password.match(minChar)) {
         newPolicy.policy4 = true;
      } else {
         newPolicy.policy4 = false;
      }

      //check other ones as well

      //set the hook
      setPolicy(newPolicy);
   }

   function validate() {
      if (!state.password || !state.confirm) {
         setError("Password and Confirm Password are required.");
         return false;
      }
      if (state.password !== state.confirm) {
         setError("Password and Confirm Password do not match.");
         return false;
      }
      if (!state.firstName) {
         setError("First Name and Last Name are required");
         return false;
      }
      if (!state.lastName) {
         setError("First Name and Last Name are required");
         return false;
      }
      setError("");
      return true;
   }

   function onSubmit(event) {
      setIsSubmitLoading(true);
      event.preventDefault();

      if (!validate()) {
         setIsSubmitLoading(false);
         return;
      }

      props.submit(state).then((resp) => {
         if (resp.success) {
            setIsSubmitLoading(false);
            setSuccess(true);
         } else {
            setIsSubmitLoading(false);
            setError(resp.err);
         }
      });
   }

   function onPasswordChange(event) {
      checkPolicy(event.target.value);
      setState({
         ...state,
         password: event.target.value,
      });
   }

   function onConfirmChange(event) {
      setState({
         ...state,
         confirm: event.target.value,
      });
   }

   function onFirstNameChange(event) {
      setState({
         ...state,
         firstName: event.target.value,
      });
   }

   function onLastNameChange(event) {
      setState({
         ...state,
         lastName: event.target.value,
      });
   }

   return (
      <div>
         {success && <Redirect to="/login"></Redirect>}

         {error && <Alert severity="error"> {error} </Alert>}
         {loading ? (
            <>
               <div className="loader-wrapper">
                  <div className="truck-wrapper-fullscreen">
                     <div className="truck">
                        <div className="truck-container">
                           <img
                              style={{ width: "65px", paddingLeft: "5px", paddingTop: "15px" }}
                              src={containerLogo}
                              alt=""
                           />
                        </div>
                        <div className="glases"></div>
                        <div className="bonet"></div>
                        <div className="base"></div>
                        <div className="base-aux"></div>
                        <div className="wheel-back"></div>
                        <div className="wheel-front"></div>
                        <div className="smoke"></div>
                     </div>
                  </div>
               </div>
            </>
         ) : validatedToken ? (
            <RegistrationForm
               handleSubmit={onSubmit}
               handlePasswordChange={onPasswordChange}
               handleConfirmChange={onConfirmChange}
               handleFirstNameChange={onFirstNameChange}
               handleLastNameChange={onLastNameChange}
               isSubmitLoading={isSubmitLoading}
               firstName={state.firstName}
               policy={policy}
               lastName={state.lastName}
               password={state.password}
               confirm={state.confirm}
            ></RegistrationForm>
         ) : (
            <ExpiredPage />
         )}
      </div>
   );
};

function mapStateToProps(state, ownProps) {
   return {};
}

function mapDispatchToProps(dispatch) {
   return {
      submit: (newUser) => dispatch(userActions.submitNewUser(newUser)),
   };
}

RegisterPage.propTypes = {
   match: PropTypes.shape({
      params: PropTypes.shape({
         id: PropTypes.string.isRequired,
         email: PropTypes.string.isRequired,
         customerid: PropTypes.string.isRequired,
      }),
   }),
   submit: PropTypes.func.isRequired,
};

RegisterPage.defaultProps = {
   match: { params: { id: "", email: "", customerid: "" } },
   submit: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
