import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Switch } from "react-router-dom";
import socketIOClient from "socket.io-client";
import { getUserMessages } from "../api/adminMessageApi";
import * as cookieTools from "../cookieTools";
import * as userActions from "../redux/actions/userActions";
import "../styles/App.css";
import "../styles/truck.css";
import containerLogo from "./Logo/ContainerPort-Logo-white.png";
// Redux
import * as notificationActions from "../redux/actions/notificationActions";
// Components
import Header from "./header/Header";
import AdminMessageDialog from "./MessageManagement/AdminMessageDialog";
import NotificationView from "./Notification/NotificationView";
import Profile from "./profile/Profile";
import QuoteHistory from "./quotehistory/QuoteHistory";
import RateSearch from "./ratesearch/RateSearch";
import SecureRoute from "./SecureRoute/SecureRoute";
import Sidebar from "./Sidebar";

const useStyles = makeStyles((theme) => ({
   contStyle: {
      margin: "0rem",
   },
   mainControl: {
      [theme.breakpoints.up("sm")]: {},
   },
   backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
   },
}));

function App(props) {
   const classes = useStyles();
   const [messages, setMessages] = useState([]);
   var inactiveTimerId;

   let [state, setState] = useState({
      selectedComponent: "ratemanagementsearch",
   });

   let [loading, setLoading] = useState(true);
   let [loggedOut, setLoggedOut] = useState(null);

   /**
    * Get messages to read for the user.
    */
   const getMessages = async () => {
      try {
         const resp = await getUserMessages();
         if (resp.length) {
            setMessages(resp);
         }
      } catch (error) {
         console.log("error in getMessages", error.message);
      }
      setLoading(false);
   };

   useEffect(() => {
      if (cookieTools.checkCookie("rate-guide-token") && cookieTools.checkCookie("rate-guide-userID")) {
         let cookieToken = cookieTools.getCookie("rate-guide-token");
         let cookieUserID = cookieTools.getCookie("rate-guide-userID");
         if (cookieToken && cookieUserID) {
            try {
               //check to see if this was filled in at login, no need to get it again
               _.isEmpty(props.user) && props.loadUser(cookieUserID);
               props.getNotifications();
               //socket connection
               const socket = socketIOClient("", { query: { user_id: cookieUserID, token: cookieToken } });
               socket.on("notification", (data) => {
                  props.getNotifications();
               });
            } catch (err) {
               console.error(err);
            }
            getMessages();
         }
      }
   }, []);

   //check to see if user still exists and to log them out
   useEffect(() => {
      try {
         if (
            _.isEmpty(props.user) &&
            !cookieTools.checkCookie("rate-guide-token") &&
            !cookieTools.checkCookie("rate-guide-userID")
         ) {
            if (loggedOut) {
               window.location.replace(`/login/loggedout`);
            } else {
               window.location.replace(`/login`);
            }
         }

         if (!_.isEmpty(props.user)) {
            if (
               _.isEmpty(props.user.customer) &&
               !cookieTools.checkCookie("rate-guide-token") &&
               !cookieTools.checkCookie("rate-guide-userID")
            ) {
               if (loggedOut) {
                  window.location.replace(`/login/loggedout`);
               } else {
                  window.location.replace(`/login`);
               }
            }
         }
      } catch (err) {
         console.error(err);
      }
   }, [props.user]);

   //Load Hotjar if user is authenticated.
   useEffect(() => {
      if (!loading && !_.isEmpty(props.user)) {
         const htmlElement = document.createElement("script");
         htmlElement.type = "text/javascript";
         htmlElement.async = true;
         htmlElement.innerHTML = `(function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:3024224,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

         document.body.appendChild(htmlElement);
      }
   }, [props.user, loading]);

   function inactiveListeners() {
      window.addEventListener("mousemove", resetTimer, false);
      window.addEventListener("mousedown", resetTimer, false);
      window.addEventListener("keypress", resetTimer, false);
      window.addEventListener("DOMMouseScroll", resetTimer, false);
      window.addEventListener("mousewheel", resetTimer, false);
      window.addEventListener("touchmove", resetTimer, false);
      window.addEventListener("MSPointerMove", resetTimer, false);

      startTimer();
   }

   inactiveListeners();

   function startTimer() {
      // wait 1 hour before logging user out.
      inactiveTimerId = window.setTimeout(goInactive, 3600000);
   }

   function resetTimer(e) {
      window.clearTimeout(inactiveTimerId);
      goActive();
   }

   function goInactive() {
      setLoggedOut("loggedout");
      props.logout();
   }

   function goActive() {
      startTimer();
   }

   const handleAcknowledgeFcn = () => {
      setMessages([]);
   };

   //need the user state to fill in for the settings on the screen
   return !loading && !_.isEmpty(props.user) ? (
      <>
         <Sidebar setParentState={setState} parentState={state} />
         <Header content={<p> Welcome {props.user.firstname}!</p>} setParentState={setState} />
         <Grid item xs>
            <div className={classes.mainControl}>
               <div className="maincontrolinner">
                  <Switch>
                     <SecureRoute exact path="/" component={RateSearch} module="ratesearch" />
                     <SecureRoute path="/profile" component={Profile} module="profile" />
                     <SecureRoute path="/quotes" component={QuoteHistory} module="quotes" />
                     <SecureRoute path="/notification" component={NotificationView} module="notification" />
                  </Switch>
               </div>
            </div>
            <footer className="footer">
               <div className="contentinside">
                  Rates based on legal weight, non hazmat shipment. Rates are subjected to availability and do not
                  include accessorial- please click{" "}
                  <a
                     href="https://drive.google.com/file/d/0B7p-67KJYXiWcnRodFlSRGpMVlE/view?resourcekey=0-K3xeUxOsPvDF_G0of0Lapw"
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     here
                  </a>{" "}
                  complete list. Quotes are valid for 90 days, and fsc changes monthly based on the DOE average. Any
                  additional questions, send an email to{" "}
                  <a href="mailto:Quote@Containerport.com">Quote@Containerport.com</a>
                  <Box>
                     <a
                        href="https://www.containerport.com/privacy-policy?hsLang=en"
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        Privacy Politics
                     </a>
                     <span style={{ display: "inline-block", margin: "5px 25px 0px 25px" }}>|</span>
                     <a
                        href="https://www.containerport.com/terms-and-conditions?hsLang=en"
                        target="_blank"
                        rel=" noopener noreferrer"
                     >
                        Terms & Conditions
                     </a>
                  </Box>
               </div>
            </footer>
         </Grid>
         {messages.length && <AdminMessageDialog messages={messages} acknowledgeFcn={handleAcknowledgeFcn} />}
      </>
   ) : (
      <>
         <div className="loader-wrapper">
            <div className="truck-wrapper-fullscreen">
               <div className="truck">
                  <div className="truck-container">
                     <img
                        style={{ width: "65px", paddingLeft: "5px", paddingTop: "15px" }}
                        src={containerLogo}
                        alt=""
                     />
                  </div>
                  <div className="glases"></div>
                  <div className="bonet"></div>
                  <div className="base"></div>
                  <div className="base-aux"></div>
                  <div className="wheel-back"></div>
                  <div className="wheel-front"></div>
                  <div className="smoke"></div>
               </div>
            </div>
         </div>
      </>
   );
}

function mapStateToProps(state) {
   return {
      user: state.user.currentUser || [],
   };
}

function mapDispatchToProps(dispatch) {
   return {
      loadUser: () => dispatch(userActions.loadUser()),
      logout: () => dispatch(userActions.logoutCurrentUser()),
      getNotifications: () => dispatch(notificationActions.getUnreadNotifications()),
   };
}

App.propTypes = {
   user: PropTypes.object.isRequired,
   loadUser: PropTypes.func.isRequired,
   logout: PropTypes.func.isRequired,
};

App.defaultProps = {
   user: {},
   loadUser: () => {
      return;
   },
   logout: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
