import { Fab } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import * as notificationApi from "../../api/notificationApi";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
   secondaryHeader: {
      borderBottom: "1px solid #2F3136",
      width: "100%",
   },
   layoutMain: {
      marginLeft: drawerWidth,
   },
   layoutBody: {
      position: "absolute",
      height: "calc(100% - 11.5rem)",
      width: "100%",
      overflowY: "scroll",
   },
}));

const LayoutHeader = () => {
   const classes = useStyles();

   return (
      <div className={classes.secondaryHeader}>
         <Grid
            xs={12}
            container
            direction="rows"
            justify="space-between"
            alignItems="center"
            style={{ height: "50px" }}
         >
            <Grid item xs={3} style={{ height: "50px" }}>
               <h2 style={{ paddingLeft: "1.5rem", marginTop: "10px", fontWeight: "500" }}>Notifications</h2>
            </Grid>
         </Grid>
      </div>
   );
};

const NotificationView = (props) => {
   const [notifications, setNotifications] = useState([]);
   const [page, setPage] = useState(0);

   const classes = useStyles();

   useEffect(() => {
      getNotification();
   }, [props]);

   function getNotification() {
      let newNotifications = _.cloneDeep(notifications);

      notificationApi
         .getNotifications(null, page)
         .then((resp) => {
            newNotifications = newNotifications.concat(resp.notifications);
            setNotifications(newNotifications);
            setPage((prev) => parseInt(prev) + 1);
            if (resp.notifications.length == 0) {
               alert(`Reached end of notifications`);
            }
         })
         .catch((err) => {
            console.error(`Error Loading Notifications: ${err}`);
         });
   }

   return (
      <>
         <div id={"notificationView"} className={classes.layoutMain}>
            {/* Header */}
            <LayoutHeader />
            {/* Timeline */}
            <div id={"notificationViewBody"} className={classes.layoutBody}>
               <Timeline align="alternate">
                  {notifications.map((not) => (
                     <TimelineItem key={`tli-${not._id}`}>
                        <TimelineSeparator>
                           <TimelineDot />
                           <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent key={not._id}>
                           <Paper style={{ padding: "2%", wordWrap: "break-word", fontSize: "12px" }}>
                              {not.notification}
                           </Paper>
                        </TimelineContent>
                     </TimelineItem>
                  ))}
               </Timeline>
               <div
                  style={{
                     width: "100%",
                     position: "absolute",
                     marginTop: "-1rem",
                     marginBottom: "1rem",
                     textAlign: "center",
                  }}
               >
                  <Tooltip title="Load More" arrow>
                     <Fab color="primary" aria-label="add" onClick={() => getNotification(notifications)}>
                        <AddIcon />
                     </Fab>
                  </Tooltip>
               </div>
            </div>
         </div>
      </>
   );
};

export default NotificationView;
